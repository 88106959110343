//create react arrow function component

import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Row,
  Typography,
  Modal,
} from "antd";
import { deleteCandidate, getCandidate } from "../../api/candidates";
import { ExclamationCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import LoadingScreen from "../../components/Common/LoadingScreen";

const CandidateInfoPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isDataFetched, setIsDataFetched] = useState(true);
  const [candidateInfo, setCandidateInfo] = useState(false);
  let navigate = useNavigate();

  const [form] = Form.useForm();

  const { id, uuid } = useParams();

  useEffect(() => {
    setIsDataFetched(false);
    getCandidate(id, uuid)
      .then(({ data }) => {
        setCandidateInfo(data);
        form.setFieldsValue(data);
        setIsDataFetched(true);
        setIsLoading(false);
      })
      .catch((err) => {
        message.error({ content: "Error Loading", key: "loading" });
        setIsDataFetched(true);
        setIsLoading(false);
      });
  }, []);

  const handleDelete = (e) => {
    setIsLoading(true);
    deleteCandidate(id, uuid)
      .then(() => {
        message.success({ content: "Deleted Successfully", key: "loading" });
        setTimeout(() => {
          setIsLoading(false);
          navigate(-1);
        }, 1000);
      })
      .catch((err) => {
        message.error({ content: "Error Deleting", key: "loading" });
        setIsLoading(false);
      });
  };
  return (
    <>
      <div className="max-w-3xl mx-auto py-4 lg:max-w-7xl inline-flex justify-between w-full">
        <button
          className="inline-flex items-center px-4 py-2 rounded-md border border-gray-400 bg-white text-sm font-medium text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:border-gray-400 shadow-sm"
          onClick={() => {
            navigate(`/async-interview/${uuid}`);
          }}
        >
          Back
        </button>
      </div>
      <div className="my-2 p-4 bg-white rounded">
        <div className="mb-12">
          <Typography.Title level={2} className="font-bold">
            {"Candidate Info"}
          </Typography.Title>
        </div>
        <hr className="mb-12" />
        {Boolean(isDataFetched && candidateInfo) ? (
          <>
            <div className="">
              <Form
                form={form}
                labelCol={{ flex: "110px" }}
                labelAlign="left"
                labelWrap
                wrapperCol={{ flex: 1 }}
                disabled={true}
                onChange={() => {
                  form.setFieldsValue(candidateInfo);
                }}
              >
                <Row className="w-full">
                  <Col span={24}>
                    <Form.Item
                      name="first_name"
                      label={
                        <div className="my-4 text-base font-bold text-gray-500 ">
                          {" "}
                          First Name
                        </div>
                      }
                    >
                      <Input className="c_input-2" />
                    </Form.Item>
                    <hr className="my-2 mb-4" />

                    <Form.Item
                      name="last_name"
                      label={
                        <div className="my-4 text-base font-bold text-gray-500">
                          {" "}
                          Last Name
                        </div>
                      }
                    >
                      <Input className="c_input-2 " />
                    </Form.Item>
                    <hr className="my-2 mb-4" />

                    <Form.Item
                      name="email"
                      label={
                        <div className="my-4 text-base font-bold text-gray-500">
                          Email
                        </div>
                      }
                    >
                      <Input className="c_input-2 " required />
                    </Form.Item>
                    <hr className="my-2 mb-4" />
                    <Form.Item
                      name="phone_number"
                      label={
                        <div className="my-4 text-base font-bold text-gray-500">
                          Contact No
                        </div>
                      }
                    >
                      <Input className="c_input-2" type="number" />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>

            <Button
              onClick={() =>
                Modal.confirm({
                  title: "Confirm",
                  centered: true,
                  icon: <ExclamationCircleOutlined />,
                  content: "Do you want to delete",
                  okText: "Yes",
                  cancelText: "Cancel",
                  onOk: () => {
                    handleDelete();
                  },
                })
              }
              type="danger"
              size="large"
              className="mt-7"
              disabled={isLoading}
            >
              <DeleteOutlined />
              Delete Candidate
            </Button>
          </>
        ) : (
          <LoadingScreen />
        )}
      </div>
    </>
  );
};

export default CandidateInfoPage;
