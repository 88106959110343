import { interviewConstants } from "../constants";
import * as _ from "lodash";

const initialState = {
  uuid: null,
  title: "",
  is_active: false,
  questions: [],
  candidate: null,
  organization_name: "",
  organization_url: "",
  organization_id: null,
  currentQuestion: null,
  isBackFromPreview: false,
  interviewStatus: "pending",
};

const candidateParser = (candidate, state) => {
  if (candidate.isDemo) {
    const tempDemoCandidate = _.clone(candidate);
    const tempDemoQuestions = _.clone(candidate.questions);
    delete tempDemoCandidate["isDemo"];
    delete tempDemoCandidate["questions"];
    return {
      ...state,
      candidate: tempDemoCandidate,
      isDemo: true,
      tempDemoQuestions,
    };
  } else {
    return {
      ...state,
      candidate: candidate,
    };
  }
};

const answerQuestionParser = (payload, state) => {
  return {
    ...state,
    tempDemoQuestions: state.tempDemoQuestions.map((question) => {
      if (question.id === payload.questionId) {
        return question.hasOwnProperty("retakes_used")
          ? {
              ...question,
              status: "submitted",
              retakes_used: payload.iseReUpload
                ? question.retakes_used
                : question.retakes_used + 1,
              answer: payload.answer,
              response_attachment_id: payload.response_attachment_id,
            }
          : {
              ...question,
              status: "submitted",
              retakes_used: 0,
              answer: payload.answer,
              response_attachment_id: payload.response_attachment_id,
            };
      }
      return question;
    }),
  };
};

const interviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case interviewConstants.GET_INTERVIEW_INFO:
      return {
        ...state,
        ...action.payload,
        questions: action.payload.questions.map((question) => {
          const oldQuestion = state.questions.find((oldQues) => {
            return oldQues.id === question.id;
          });
          return {
            ...oldQuestion,
            ...question,
            status: oldQuestion?.status || "pending",
          };
        }),
      };
    case interviewConstants.CREATE_CANDIDATE:
      return candidateParser(action.payload, state);
    case interviewConstants.UPDATE_CANDIDATE_INFO:
      return { ...state, candidate: { ...state.candidate, ...action.payload } };
    case interviewConstants.UPLOAD_ANSWER_REQUEST:
      return {
        ...state,
        questions: state.questions.map((question) => {
          if (question.id === action.payload.questionId) {
            return { ...question, status: "uploading" };
          }
          return question;
        }),
      };
    case interviewConstants.RETAKE_QUESTION:
      if (action.isDemo) {
        return {
          ...state,
          tempDemoQuestions: state.tempDemoQuestions.map((question) => {
            if (question.id === action.payload) {
              return { ...question, status: "pending", answer: null };
            }
            return question;
          }),
        };
      } else {
        return {
          ...state,
          questions: state.questions.map((question) => {
            if (question.id === action.payload) {
              return { ...question, status: "pending", answer: null };
            }
            return question;
          }),
        };
      }

    case interviewConstants.UPLOAD_ANSWER_SUCCESS:
      if (state.currentQuestion === state.questions.length - 1) {
        //to go to preview page after last question
        return {
          ...state,
          currentQuestion: -1,
          isBackFromPreview: true,
          questions: state.questions.map((question) => {
            if (question.id === action.payload.questionId) {
              return {
                ...question,
                status: "preview",
              };
            } else {
              return { ...question, status: "preview" };
            }
            return question;
          }),
        };
      } else {
        //to go to next question after uploading answer
        return {
          ...state,
          questions: state.questions.map((question, i) => {
            if (question.id === action.payload.questionId) {
              return {
                ...question,
                status: "uploaded",
              };
            }
            return question;
          }),
        };
      }

    case interviewConstants.UPLOAD_ANSWER_FAILURE:
      return {
        ...state,
        questions: state.questions.map((question) => {
          if (question.id === action.payload.questionId) {
            return { ...question, status: "submitted" };
          }
          return question;
        }),
      };
    case interviewConstants.ANSWER_QUESTION:
      if (action.payload.isDemo) {
        return answerQuestionParser(action.payload, state);
      } else {
        if (action.payload.response_type === "text") {
          return {
            ...state,
            questions: state.questions.map((question) => {
              if (question.id === action.payload.questionId) {
                return question.hasOwnProperty("retakes_used")
                  ? {
                      ...question,
                      answer: action.payload.answer,
                      status: "submitted",
                      retakes_used: question.retakes_used + 1,
                    }
                  : {
                      ...question,
                      answer: action.payload.answer,
                      status: "submitted",
                      retakes_used: 0,
                    };
              }
              return question;
            }),
          };
        } else {
          return {
            ...state,
            questions: state.questions.map((question) => {
              if (question.id === action.payload.questionId) {
                return question.hasOwnProperty("retakes_used")
                  ? {
                      ...question,
                      status: "submitted",
                      retakes_used: action.payload.iseReUpload
                        ? question.retakes_used
                        : question.retakes_used + 1,
                      answer: action.payload.answer,
                      response_attachment_id:
                        action.payload.response_attachment_id,
                    }
                  : {
                      ...question,
                      status: "submitted",
                      retakes_used: 0,
                      answer: action.payload.answer,
                      response_attachment_id:
                        action.payload.response_attachment_id,
                    };
              }
              return question;
            }),
          };
        }
      }

    case interviewConstants.ANSWER_QUESTION_TEMP:
      return {
        ...state,
        questions: state.questions.map((question) => {
          if (question.id === action.payload.questionId) {
            return {
              ...question,
              answer: action.payload.answer,
            };
          }
          return question;
        }),
      };

    case interviewConstants.SET_CURRENT_QUESTION:
      return {
        ...state,
        currentQuestion: action.payload,
        questions: state.questions.map((question, i) => {
          if (i === action.payload) {
            return { ...question, status: "pending" };
          } else {
            return question;
          }
        }),
      };
    case interviewConstants.SUBMIT_INTERVIEW_REQUEST:
      return {
        ...state,
        interviewStatus: "submitting",
      };
    case interviewConstants.SUBMIT_INTERVIEW_SUCCESS:
      return {
        ...state,
        interviewStatus: "submitted",
        currentQuestion: -2,
      };
    case interviewConstants.SUBMIT_INTERVIEW_FAILURE:
      return {
        ...state,
        interviewStatus: "pending",
      };
    case interviewConstants.RECOVER_INTERVIEW:
      return action.payload;
    case interviewConstants.RESET_DEMO: {
      delete action.payload.isDemo;
      action.payload.candidate = null;
      delete action.payload.tempDemoQuestions;
      return action.payload;
    }
    default:
      return state;
  }
};

export default interviewReducer;
