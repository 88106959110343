import { getEvent } from "../../api/events";
import { useParams } from "react-router-dom";
import { Typography } from "antd";
import { useEffect, useState } from "react";

const EventHeaderComponent = () => {
  const [eventName, setEventName] = useState(false);
  const { uuid, roomId } = useParams();
  useEffect(() => {
    getEvent(roomId, uuid)
      .then(({ data }) => {
        setEventName(data.name);
      })
      .catch((err) => {});
  }, []);

  return (
    <Typography.Title className="text-2xl mb-5 font-bold">
      {eventName}
    </Typography.Title>
  );
};
export default EventHeaderComponent;
