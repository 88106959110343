import React from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const environment = process.env.SENTRY_ORG;

if (environment === "production" || environment === "development") {
  Sentry.init({
    environment: environment,
    dsn: "https://6ad9e19919494a538faf65de11d4a302@o1160636.ingest.sentry.io/4504084275527680",
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

reportWebVitals();
