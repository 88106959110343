import { message, Table, Typography } from "antd";
import React from "react";
import { getMessages } from "../../api/messages";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import EventHeaderComponent from "../../components/Event/EventHeaderComponent";

const MessagesPage = () => {
  let navigate = useNavigate();
  const [messages, setMessages] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const { uuid } = useParams();

  const messageColumns = [
    {
      title: "Sent By",
      dataIndex: "author_name",
      key: "author_name",
      width: "20%",
      sorter: (a, b) => a.author_name.localeCompare(b.author_name),
      sortDirections: ["descend", "ascend"],
      render: (data) => <div className="text-base font-medium">{data}</div>,
    },
    {
      title: "Content",
      dataIndex: "content",
      key: "content",
      width: "60%",
      sorter: (a, b) => a.content.localeCompare(b.content),
      sortDirections: ["descend", "ascend"],
      render: (data) => <div className="text-base text-black-100">{data}</div>,
    },
    {
      title: "Sent At",
      dataIndex: "created",
      key: "created",
      width: "15%",
      sorter: (a, b) =>
        new Date(a.created).getTime() - new Date(b.created).getTime(),
      render: (date) => (
        <div className="text-base text-gray-400">
          {moment(date).format("lll")}
        </div>
      ),
    },
  ];

  React.useEffect(() => {
    setLoading(true);
    message.loading({
      key: "messages",
      duration: 0,
      content: "Loading Messages....",
    });
    getMessages(uuid)
      .then(({ data }) => {
        let { results } = data;
        setMessages(results);
        setLoading(false);
        message.destroy("messages");
      })
      .catch((error) => {
        message.error({ key: "messages", content: "Error loading messages" });
      });
  }, []);
  return (
    <div>
      <div className="max-w-3xl mx-auto py-4 lg:max-w-7xl inline-flex justify-between w-full">
        <button
          className="inline-flex items-center px-4 py-2 rounded-md border border-gray-400 bg-white text-sm font-medium text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:border-gray-400 shadow-sm"
          onClick={() => {
            navigate("/interview");
          }}
        >
          Back
        </button>
      </div>
      <div>
        <EventHeaderComponent />
        <div className="flex items-center justify-between mb-8">
          <Typography.Text className="text-xl font-medium">
            Messages
          </Typography.Text>
        </div>
        <hr className="mb-7" />
        <div
          className="m-0 border shadow rounded-lg px-2 pt-1 -mx-4 divide-gray-200"
          style={{
            maxWidth: "100vw",
          }}
          id="custom-table"
        >
          <Table
            scroll={{ x: 1000 }}
            columns={messageColumns}
            dataSource={messages}
            rowKey={(record) => record.id}
            className="w-full"
          />
        </div>
      </div>
    </div>
  );
};

export default MessagesPage;
