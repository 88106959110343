import { useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import interviewActions from "../../redux/actions/interview.actions";
import { Typography } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { ShareComponent } from "./InterviewGettingStartedPage";

export const checkURLParameters = (checkUrlParams) => {
  // Get the URL from the current window location
  const url = new URL(checkUrlParams);

  // Check if the URL has the required parameters
  const candidateName = url.searchParams.get("candidate_name")?.toString();
  const candidateEmail = url.searchParams.get("candidate_email")?.toString();
  const candidatePhone = url.searchParams.get("candidate_phone")?.toString();

  // All parameters are present, extract the first name, last name, email, and phone number
  const [firstName, lastName] = candidateName?.split(" ") || "".split(" ");

  const decodedEmail = candidateEmail
    ? decodeURIComponent(candidateEmail?.replace(/ /g, "+"))
    : "";
  const data = {
    first_name: firstName || "",
    last_name: lastName || "",
    email: decodedEmail || "",
    phone_number: candidatePhone || "",
  };
  return data;
};

export default function InterViewInvitePage() {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [interviewStatus, setInterviewStatus] = useState("idle");
  const [interviewStatusMessage, setInterviewStatusMessage] = useState("");
  const [tempCandidate, setTempCandidate] = useState({});

  const useQuery = () => new URLSearchParams(useLocation().search);
  let query = useQuery();

  const interview = useSelector((state) => state.interview);
  useEffect(() => {
    if (!interview.uuid)
      interviewActions.getInterview(
        uuid,
        (isLoading) => {
          isLoading && setInterviewStatus("loading");
        },
        (candidate) => {
          setInterviewStatus("success");
          setTempCandidate(candidate);
        },
        (error) => {
          setInterviewStatus("error");
          setInterviewStatusMessage(error);
        },
        interview
      )(dispatch, window.location.href, true);
  }, []);

  useEffect(() => {
    const session = JSON.parse(window.localStorage.getItem("session") || "{}");
    if (uuid === session.uuid && session.candidate) {
      navigate("/async-interview/" + uuid + "/test");
    } else {
      window.localStorage.removeItem("session");
    }
  }, [interview]);

  if (interviewStatus === "loading") {
    return (
      <div className="flex w-full justify-center items-center min-h-[500px]">
        <p className="text-lg text-center">Loading...</p>
      </div>
    );
  } else if (interviewStatus === "error") {
    return (
      <div className="flex w-full justify-center items-center min-h-[500px] text-red-500">
        <p className="text-lg text-center">{interviewStatusMessage}</p>
      </div>
    );
  } else {
    return (
      <div>
        <div
          className="p-4 bg-white rounded-md w-full mx-auto"
          style={{ maxWidth: "630px" }}
        >
          {interview.uuid ? (
            <>
              <div className="mb-12 space-y-4">
                <Typography.Title level={2} className="font-bold">
                  {interview.title}
                </Typography.Title>
                <div className="flex justify-between items-center">
                  <div className="font-bold text-xl">
                    {interview.organization_name || "Tacnique"}
                  </div>
                  <div className="py-2 px-8 text-white border border-parrotGreen-900 bg-parrotGreen-500 rounded-full font-bold">
                    {interview.is_active ? "Live" : "Offline"}
                  </div>
                </div>
                <hr />
                <div>
                  <a
                    href={
                      interview.organization_url ||
                      process.env.REACT_APP_REDIRECT_URL
                    }
                    target="_blank"
                    rel="noreferrer"
                    className="text-black text-base font-bold"
                  >
                    {interview.organization_url ||
                      process.env.REACT_APP_REDIRECT_URL}
                  </a>
                </div>
              </div>
              <div className="text-center mb-4">
                <button
                  className="bg-primary-800 text-white py-4 px-12 rounded-full text-xl cursor-pointer disabled:cursor-not-allowed "
                  onClick={() => {
                    const url = window.location.href;
                    navigate(
                      "/async-interview/" +
                        uuid +
                        "/test?".concat(url.split("/invite?")[1] || "")
                    );
                  }}
                  disabled={!interview.is_active}
                >
                  Get Started
                </button>
              </div>
              <div>
                {/*<p className="text-center text-base mt-4">*/}
                {/*    <span><FileTextOutlined/></span> By clicking "Get Started", I agree to the <span>*/}
                {/*    <Link to={`${process.env.REACT_APP_REDIRECT_URL}/about`}> Terms and Conditions and Privacy policy.</Link>*/}
                {/*</span>*/}
                {/*</p>*/}
                <p className="text-center text-base mt-4 text-gray-400">
                  <span>
                    <ExclamationCircleOutlined />
                  </span>{" "}
                  This digital video interview contains{" "}
                  {interview.questions.length} questions that you can answer in
                  your own time using your smartphone, tablet, or computer with
                  a webcam.
                </p>
              </div>
            </>
          ) : (
            <div className="p-4 -mt-12 bg-white rounded-md min-h-[500px] flex items-center justify-center">
              <Typography.Title level={3} className="text-center ">
                No interview to show
              </Typography.Title>
            </div>
          )}
        </div>
        <ShareComponent />
      </div>
    );
  }
}
