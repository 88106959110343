import { interviewConstants } from "../constants";
import { message } from "antd";
import * as interviewService from "../../api/interviews";
import * as candidateService from "../../api/candidates";
import { checkURLParameters } from "../../pages/interview/InterviewInvitePage";
import * as _ from "lodash";

const getInterview = (
  uuid,
  loadingCallback,
  successCallback,
  errorCallback,
  interviewState
) => {
  return (dispatch, url, bypassCandidate) => {
    loadingCallback && loadingCallback(true);
    interviewService
      .getInterviewQuestions(uuid, interviewState.isDemo)
      .then(({ data }) => {
        const candidate = checkURLParameters(url);
        // message.success({key: 'interview', content: 'Interview Loaded Successfully', duration: '2'});
        let session;
        message.destroy("interview");
        if (window.localStorage.getItem("session")) {
          session = JSON.parse(window.localStorage.getItem("session"));
          if (session.uuid === uuid && !session.isDemo)
            dispatch({
              type: interviewConstants.RECOVER_INTERVIEW,
              payload: session,
            });
          else {
            window.localStorage.removeItem("session");
          }
        }
        if (!bypassCandidate) {
          if (!(candidate || session?.candidate)) {
            throw new Error("Link is incorrect, verify the link.");
          }
        }
        dispatch({
          type: interviewConstants.GET_INTERVIEW_INFO,
          payload: data[0],
        });
        loadingCallback && loadingCallback(false);
        successCallback && successCallback(candidate);
      })
      .catch((err) => {
        loadingCallback && loadingCallback(false);
        errorCallback &&
          errorCallback(
            (err.message.includes("Request failed with status code 400") &&
              "No interview to show") ||
              err.message ||
              "Something went wrong"
          );
        message.error({
          key: "interview",
          content: "Interview Loading Failed",
        });
      });
  };
};

const updateCandidateInfo = (changes) => {
  return (dispatch) => {
    dispatch({
      type: interviewConstants.UPDATE_CANDIDATE_INFO,
      payload: changes,
    });
  };
};

const answerQuestion = (
  currentIndex,
  response_type,
  questionId,
  candidateId,
  interviewId,
  data,
  loadingCallback,
  successCallback,
  errorCallback,
  interviewState
) => {
  return (dispatch, iseReUpload) => {
    if (response_type === "text") {
      dispatch({
        type: interviewConstants.ANSWER_QUESTION,
        payload: {
          questionId,
          answer: data,
          response_type,
        },
      });
    } else {
      loadingCallback(true);
      interviewService
        .uploadAnswer(
          response_type,
          {
            question_id: questionId,
            candidate_id: candidateId,
            interview_id: interviewId,
          },
          data,
          interviewState.isDemo
        )
        .then((res) => {
          res = res.data;
          // message.success({
          //   key: "upload",
          //   content: "Question Uploaded Successfully",
          //   duration: "2",
          // });
          dispatch({
            type: interviewConstants.ANSWER_QUESTION,
            payload: {
              questionId,
              answer: res.text_response || res.url,
              response_attachment_id: res.id,
              response_type,
              iseReUpload,
              isDemo: interviewState.isDemo,
            },
          });
          successCallback(true);
          loadingCallback(false);
        })
        .catch((err) => {
          errorCallback(err.message);
          loadingCallback(false);
          message.error({ key: "upload", content: "Question Upload Failed" });
        });
    }
  };
};

const answerQuestionTemp = (questionId, answer) => {
  return (dispatch) => {
    dispatch({
      type: interviewConstants.ANSWER_QUESTION_TEMP,
      payload: {
        questionId,
        answer,
      },
    });
  };
};

const retakeQuestion = (questionId, isDemo) => {
  return (dispatch) => {
    dispatch({
      type: interviewConstants.RETAKE_QUESTION,
      payload: questionId,
      isDemo,
    });
  };
};

const submitInterview = (interviewId, candidateId, attachments) => {
  return (dispatch) => {
    let payload = {
      response: attachments,
      interview_id: interviewId,
      candidate_id: candidateId,
    };
    message.loading({ content: "Submitting Interview...", key: "submit" });
    dispatch({
      type: interviewConstants.SUBMIT_INTERVIEW_REQUEST,
    });
    interviewService
      .submitInterview(interviewId, payload)
      .then(({ data }) => {
        dispatch({
          type: interviewConstants.SUBMIT_INTERVIEW_SUCCESS,
          payload: data,
        });
        message.success({
          key: "submit",
          content: "Interview Submitted Successfully",
          duration: "2",
        });
      })
      .catch((err) => {
        dispatch({
          type: interviewConstants.SUBMIT_INTERVIEW_FAILURE,
        });
        message.error({
          key: "submit",
          content: "Interview Submission Failed",
        });
      });
  };
};

const uploadAnswer = (
  currentIndex,
  response_type,
  questionId,
  candidateId,
  interviewId,
  data,
  callback
) => {
  return (dispatch) => {
    // message.loading({ content: "Saving Question...", key: "upload" });
    if (response_type === "text") {
      dispatch({
        type: interviewConstants.UPLOAD_ANSWER_SUCCESS,
        payload: {
          questionId,
        },
      });
    } else {
      dispatch({
        type: interviewConstants.UPLOAD_ANSWER_SUCCESS,
        payload: {
          questionId,
        },
      });
    }
    // dispatch({
    //   type: interviewConstants.UPLOAD_ANSWER_REQUEST,
    //   payload: {
    //     questionId,
    //   },
    // });
  };
};
const setCurrentQuestion = (questionNo) => {
  return (dispatch) => {
    dispatch({
      type: interviewConstants.SET_CURRENT_QUESTION,
      payload: questionNo,
    });
  };
};
const createCandidate = (candidate, isDemo) => {
  return (dispatch) => {
    message.loading({ content: "Starting...", key: "candidate" });
    candidateService
      .createCandidate(candidate, isDemo)
      .then(({ data }) => {
        dispatch({
          type: interviewConstants.CREATE_CANDIDATE,
          payload: data,
        });
        message.destroy("candidate");
        setCurrentQuestion(0)(dispatch);
      })
      .catch((err) => {
        message.error({
          key: "candidate",
          content: "Candidate Creation Failed",
        });
      });
  };
};

const resetDemo = (interviewState) => {
  return (dispatch) => {
    const tempInterviewState = _.clone(interviewState);
    dispatch({
      type: interviewConstants.RESET_DEMO,
      payload: tempInterviewState,
    });
  };
};

export default {
  getInterview,
  updateCandidateInfo,
  answerQuestion,
  createCandidate,
  setCurrentQuestion,
  uploadAnswer,
  retakeQuestion,
  submitInterview,
  answerQuestionTemp,
  resetDemo,
};
