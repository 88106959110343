export const interviewConstants = {
  GET_INTERVIEW_INFO: "GET_INTERVIEW_INFO",
  UPDATE_CANDIDATE_INFO: "UPDATE_CANDIDATE_INFO",
  ANSWER_QUESTION: "ANSWER_QUESTION",
  CREATE_CANDIDATE: "CREATE_CANDIDATE",
  NEXT_QUESTION: "NEXT_QUESTION",
  SET_CURRENT_QUESTION: "SET_CURRENT_QUESTION",
  UPLOAD_ANSWER_REQUEST: "UPLOAD_ANSWER_REQUEST",
  UPLOAD_ANSWER_SUCCESS: "UPLOAD_ANSWER_SUCCESS",
  UPLOAD_ANSWER_FAILURE: "UPLOAD_ANSWER_FAILURE",
  RETAKE_QUESTION: "RETAKE_QUESTION",
  SUBMIT_INTERVIEW_REQUEST: "SUBMIT_INTERVIEW_REQUEST",
  SUBMIT_INTERVIEW_SUCCESS: "SUBMIT_INTERVIEW_SUCCESS",
  SUBMIT_INTERVIEW_FAILURE: "SUBMIT_INTERVIEW_FAILURE",
  RECOVER_INTERVIEW: "RECOVER_INTERVIEW",
  ANSWER_QUESTION_TEMP: "ANSWER_QUESTION_TEMP",
  RESET_DEMO: "RESET_DEMO",
};
