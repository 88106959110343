import API from "./axios";

export const getControls = (event_id) => {
  return API.get("/rooms/events/controls/", {
    params: {
      event_id,
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem("api_key")}`,
    },
  });
};
