import { useEffect, useState } from "react";
import ReactQuill from "react-quill";

export const checkCameraSupport = () => {
  return new Promise(async (resolve, reject) => {
    const AudioConstraint = { audio: true };
    const VideoConstraint = { video: true };
    let error = { audio: true, video: true };

    await navigator.mediaDevices.getUserMedia(AudioConstraint).catch(() => {
      error.audio = false;
    });
    await navigator.mediaDevices.getUserMedia(VideoConstraint).catch(() => {
      error.video = false;
    });
    switch (true) {
      case error.audio === false && error.video === false:
        reject(new Error("Media devices not available"));
        break;
      case error.audio === false:
        reject(new Error("Microphone not available"));
        break;
      case error.video === false:
        reject(new Error("Camera not available"));
        break;

      default:
        resolve({ success: "success" });
        break;
    }
  });
};

export const handleConcat = (string, limit) => {
  if (string.length < limit) {
    return string;
  }

  let nearestEmptySpace = -1;

  for (let i = 0; i <= limit; i++) {
    if (string[i] === " ") {
      nearestEmptySpace = i;
    }
  }

  if (nearestEmptySpace !== -1) {
    const firstPart = string.slice(0, nearestEmptySpace).trim();
    return firstPart.concat("...");
  }

  return string;
};

export const CustomInput = ({ value, onChange }) => {
  return (
    <div className="w-full questionTextEditor">
      <ReactQuill theme="snow" value={value} onChange={onChange} />
    </div>
  );
};

export const errorValidator = (_, value) => {
  // Remove HTML tags and trim whitespace
  const cleanedValue = value.replace(/<[^>]+>/g, "").trim();
  if (
    cleanedValue === undefined ||
    cleanedValue === "" ||
    cleanedValue.length === 0
  ) {
    return Promise.reject(new Error("Please Enter The Question"));
  } else {
    return Promise.resolve();
  }
};

export const DebouncedInput = ({
  debounce = 500,
  uniqueKey,
  hideLabel,
  inputRender,
  initialValue,
  onChange,
  ...props
}) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <div className="flex items-center w-full px-2">
      {inputRender(value, (e) => setValue(e.target.value))}
    </div>
  );
};

export const classNames = (...classes) => classes?.filter(Boolean)?.join(" ");

export const matchStringCharacters = (str = '') => str?.replace(/[^a-zA-Z0-9]/g, "")?.toLowerCase();
