import { message, Table, Typography } from "antd";
import React from "react";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { getParticipants } from "../../api/participants";
import EventHeaderComponent from "../../components/Event/EventHeaderComponent";

const ParticipantsPage = () => {
  let navigate = useNavigate();
  const [participants, setParticipants] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const { uuid } = useParams();

  const participantsColumns = [
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      sorter: (a, b) => a.username.localeCompare(b.username),
      render: (data) => <div className="text-base font-medium">{data}</div>,
    },
    {
      title: "Joined At",
      dataIndex: "created",
      key: "created",
      width: "20%",
      sorter: (a, b) =>
        new Date(a.created).getTime() - new Date(b.created).getTime(),
      render: (date) => (
        <div className="text-base text-gray-400">
          {moment(date).format("lll")}
        </div>
      ),
    },
    {
      title: "Left At",
      dataIndex: "modified",
      key: "modified",
      width: "20%",
      sorter: (a, b) =>
        new Date(a.modified).getTime() - new Date(b.modified).getTime(),
      render: (date) => (
        <div className="text-base text-gray-400">
          {moment(date).format("lll")}
        </div>
      ),
    },
  ];

  React.useEffect(() => {
    setLoading(true);
    message.loading({
      key: "messages",
      duration: 0,
      content: "Loading Participants....",
    });
    getParticipants(uuid)
      .then(({ data }) => {
        let { results } = data;
        setParticipants(results.filter((participant) => participant.username));
        setLoading(false);
        message.destroy("messages");
      })
      .catch((error) => {
        message.error({
          key: "messages",
          content: "Error loading Participants",
        });
      });
  }, []);
  return (
    <div>
      <div className="max-w-3xl mx-auto py-4 lg:max-w-7xl inline-flex justify-between w-full">
        <button
          className="inline-flex items-center px-4 py-2 rounded-md border border-gray-400 bg-white text-sm font-medium text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:border-gray-400 shadow-sm"
          onClick={() => {
            navigate("/interview");
          }}
        >
          Back
        </button>
      </div>
      <div>
        <EventHeaderComponent />
        <div className="flex items-center justify-between mb-8">
          <Typography.Text className="text-xl font-medium">
            Participants
          </Typography.Text>
        </div>
        <hr className="mb-7" />
        <div
          className="m-0 border shadow w-full rounded-lg px-2 pt-1 -mx-4 divide-gray-200"
          style={{
            maxWidth: "100vw",
          }}
          id="custom-table"
        >
          <Table
            columns={participantsColumns}
            dataSource={participants}
            scroll={{ x: 1000 }}
            rowKey={(record) => record.id}
          />
        </div>
      </div>
    </div>
  );
};

export default ParticipantsPage;
