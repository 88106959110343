import { Input, message, Modal, Table, Tag, Tooltip, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import { deleteResponse, getResponses } from "../../api/responses";
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  RightOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import { updateCandidate } from "../../api/candidates";
import { getInterview } from "../../api/interviews";
import { DebouncedInput } from "../../utilities";
import LoadingScreen from "../../components/Common/LoadingScreen";

const InterviewResponsesPage = () => {
  let { uuid } = useParams();

  const handleDeleteResponse = (id, data) => {
    message.loading({ content: "Deleting...", key: "delete" });
    deleteResponse(id, data)
      .then(() => {
        message.success({ content: "Deleted Successfully", key: "delete" });
        setInterviewInfo(
          interviewInfo.filter((response) => response.id !== id)
        );
      })
      .catch(() => {
        message.error({ content: "Error deleting response", key: "delete" });
      });
  };

  const updateList = (id, data) => {
    setInterviewInfo(
      interviewInfo.map((response) =>
        response.candidate.id === data.id
          ? {
              ...response,
              candidate: data,
            }
          : response
      )
    );
  };
  const handleApproveCandidate = (id) => {
    message.loading({ content: "Approving Candidate", key: "approve" });
    updateCandidate(id, uuid, { hiring_status: "approved" })
      .then(({ data }) => {
        message.success({
          content: "Candidate Approved Successfully",
          key: "approve",
        });
        updateList(id, data);
      })
      .catch((err) => {
        message.error({
          content: "Error approving the candidate",
          key: "approve",
        });
      });
  };
  const handleRejectCandidate = (id) => {
    message.loading({
      content: "Rejecting Candidate",
      key: "reject",
      duration: 0,
    });
    updateCandidate(id, uuid, { hiring_status: "rejected" })
      .then(({ data }) => {
        message.success({
          content: "Candidate Rejected Successfully",
          key: "reject",
        });
        updateList(id, data);
      })
      .catch((err) => {
        message.error({
          content: "Error rejecting the candidate",
          key: "reject",
        });
      });
  };

  const [interviewInfo, setInterviewInfo] = useState([]);
  const [interviewTitle, setInterviewTitle] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const columns = [
    {
      title: "Name",
      dataIndex: "first_name",
      key: "first_name",
      width: "20%",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) =>
        a.candidate.first_name.localeCompare(b.candidate.first_name),
      render: (text, record) => (
        <Link
          to={
            "/async-interview/" +
            uuid +
            "/responses/" +
            record.candidate_id +
            "/candidate"
          }
        >
          <span className="text-base font-medium text-primary-500">
            {record.candidate.first_name} {record.candidate.last_name}
          </span>
        </Link>
      ),
    },
    {
      title: "Phone No",
      dataIndex: "phone_number",
      key: "phone_number",
      width: "15%",
      sorter: (a, b) =>
        a.candidate.phone_number.localeCompare(b.candidate.phone_number),
      sortDirections: ["descend", "ascend"],
      render: (text, record) => (
        <div className="text-base text-black-100">
          {record.candidate.phone_number}
        </div>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "15%",
      sorter: (a, b) => a.candidate.email.localeCompare(b.candidate.email),
      sortDirections: ["descend", "ascend"],
      render: (text, record) => (
        <div className="text-base text-black-100">{record.candidate.email}</div>
      ),
    },
    {
      title: "Hiring Status",
      dataIndex: "hiring_status",
      key: "hiring_status",
      sorter: (a, b) =>
        a.candidate.hiring_status.localeCompare(b.candidate.hiring_status),
      sortDirections: ["descend", "ascend"],
      width: "10%",
      render: (text, record) => {
        if (record.candidate.hiring_status === "applied") {
          return (
            <Tag
              className="text-center"
              color="#9254de"
              style={{ width: "70px" }}
            >
              Applied
            </Tag>
          );
        } else if (record.candidate.hiring_status === "approved") {
          return (
            <Tag
              className="text-center"
              color="green"
              style={{ width: "70px" }}
            >
              Approved
            </Tag>
          );
        } else if (record.candidate.hiring_status === "rejected") {
          return (
            <Tag
              className="text-center"
              color="volcano"
              style={{ width: "70px" }}
            >
              Rejected
            </Tag>
          );
        }
      },
    },

    {
      title: "Posted On",
      dataIndex: "created",
      key: "created",
      width: "10%",
      sorter: (a, b) =>
        new Date(a.created).getTime() - new Date(b.created).getTime(),
      render: (date) => (
        <div className="text-base text-gray-400">
          {moment(date).format("lll")}
        </div>
      ),
    },
    {
      title: "",
      dataIndex: "candidate.email",
      key: "candidate.email",
      width: "10%",

      render: (text, record) => (
        <div className="flex">
          {record && record.candidate.hiring_status && (
            <>
              {record.candidate.hiring_status === "applied" && (
                <>
                  <Tooltip
                    title="Approve Candidate"
                    color={"#21BCAB"}
                    key={"#21BCAB"}
                  >
                    <button
                      className="btn-icon hover-green"
                      type="button"
                      onClick={() =>
                        Modal.confirm({
                          title: "Confirm",
                          centered: true,
                          icon: <ExclamationCircleOutlined />,
                          content: "Do you want to approve this candidate",
                          okText: "Yes",
                          cancelText: "Cancel",
                          onOk: () => {
                            handleApproveCandidate(record.candidate_id, record);
                          },
                        })
                      }
                    >
                      <CheckOutlined />
                    </button>
                  </Tooltip>
                  <Tooltip
                    title="Reject Candidate"
                    color="#FF8C00"
                    key="#FF8C00"
                  >
                    <button
                      className="btn-icon hover-orange"
                      type="button"
                      onClick={() =>
                        Modal.confirm({
                          title: "Confirm",
                          centered: true,
                          icon: <ExclamationCircleOutlined />,
                          content: "Do you want to reject this candidate",
                          okText: "Yes",
                          cancelText: "Cancel",
                          onOk: () => {
                            handleRejectCandidate(record.candidate_id, record);
                          },
                        })
                      }
                    >
                      <CloseOutlined />
                    </button>
                  </Tooltip>
                </>
              )}
              {record.candidate.hiring_status === "approved" && (
                <>
                  <Tooltip
                    title="Reject Candidate"
                    color="#FF8C00"
                    key="#FF8C00"
                  >
                    <button
                      className="btn-icon hover-orange"
                      type="danger"
                      onClick={() =>
                        Modal.confirm({
                          title: "Confirm",
                          centered: true,
                          icon: <ExclamationCircleOutlined />,
                          content: "Do you want to reject this candidate",
                          okText: "Yes",
                          cancelText: "Cancel",
                          onOk: () => {
                            handleRejectCandidate(record.candidate_id, record);
                          },
                        })
                      }
                    >
                      <CloseOutlined />
                    </button>
                  </Tooltip>
                </>
              )}
              {record.candidate.hiring_status === "rejected" && (
                <>
                  <Tooltip
                    title="Approve Candidate"
                    color={"#21BCAB"}
                    key={"#21BCAB"}
                  >
                    <button
                      className="btn-icon hover-green"
                      type="danger"
                      onClick={() =>
                        Modal.confirm({
                          title: "Confirm",
                          centered: true,
                          icon: <ExclamationCircleOutlined />,
                          content: "Do you want to approve this candidate",
                          okText: "Yes",
                          cancelText: "Cancel",
                          onOk: () => {
                            handleApproveCandidate(record.candidate_id, record);
                          },
                        })
                      }
                    >
                      <CheckOutlined />
                    </button>
                  </Tooltip>
                </>
              )}
            </>
          )}
          <Tooltip title="Delete Response" color="#FF4D4D" key="#FF4D4D">
            <button
              className="btn-icon hover-red"
              type="danger"
              onClick={() =>
                Modal.confirm({
                  title: "Confirm",
                  centered: true,
                  icon: <ExclamationCircleOutlined />,
                  content: "Do you want to delete",
                  okText: "Yes",
                  cancelText: "Cancel",
                  onOk: () => {
                    handleDeleteResponse(record.id, record);
                  },
                })
              }
            >
              <DeleteOutlined />
            </button>
          </Tooltip>
          <Tooltip title="Share Response" color={"#7e51b5"} key={"#7e51b5"}>
            <button
              className="btn-icon hover-purple"
              type="button"
              onClick={() => {
                const url = `${process.env.REACT_APP_FRONTEND_URL}/async-interview/${record.uuid}/share`;
                navigator.clipboard.writeText(url);
                message.success({
                  content: "Link Copied to Clipboard",
                  key: "copy",
                });
              }}
            >
              <ShareAltOutlined />
            </button>
          </Tooltip>
          <Tooltip title="View Response" color="#1945a4" key="#1945a4">
            <Link
              to={"/async-interview/" + uuid + "/responses/" + record.id}
              className="btn-icon hover-blue"
              type="primary"
            >
              <RightOutlined />
            </Link>
          </Tooltip>
        </div>
      ),
    },
  ];

  useEffect(() => {
    setIsLoading(true);
    getInterview(uuid)
      .then(({ data }) => {
        setIsLoading(false);
        setInterviewTitle(data.title);
      })
      .catch((err) => {});
    getResponses(uuid, searchValue)
      .then(({ data }) => {
        setIsLoading(false);
        setInterviewInfo(data);
      })
      .catch((err) => {
        message.error({
          content: "Error Loading Responses...",
          key: "responses",
        });
        setIsLoading(false);
      });
  }, [searchValue]);

  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <div className="px-2 md:px-0">
          <div className="flex justify-between">
            <div>
              {interviewTitle && (
                <div className="mb-5">
                  <Typography.Text className="text-2xl font-bold">
                    {interviewTitle}
                  </Typography.Text>
                </div>
              )}
            </div>
            <div className="mt-1">
              <DebouncedInput
                onChange={(value) => {
                  setSearchValue(value);
                }}
                initialValue={searchValue}
                inputRender={(value, onChange) => {
                  return (
                    <Input
                      placeholder="Search by name or email"
                      value={value}
                      onChange={onChange}
                      style={{ width: 250 }}
                      size="large"
                      className="rounded shadow border border-gray-300 hover:border-primary-800 focus-within:border-primary-800"
                      prefix={
                        <i className="fa-solid fa-magnifying-glass text-gray-400 mr-2"></i>
                      }
                    />
                  );
                }}
              />
            </div>
          </div>
          <div
            className="m-0 border shadow rounded-lg px-2 pt-1 min-w-full divide-gray-200"
            style={{
              overflow: "auto",
              maxWidth: "100vw",
            }}
            id="custom-table"
          >
            <Table
              columns={columns}
              dataSource={interviewInfo}
              rowKey={(record) => record.id}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default InterviewResponsesPage;