const LoadingScreen = () => {
  return (
    <main className="flex items-center justify-center my-16">
      <span
        data-test-id="loading-box"
        className="font-bold text-2xl text-primary-800"
      >
        Loading...
      </span>
    </main>
  );
};

export default LoadingScreen;
