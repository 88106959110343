import API from "./axios";

export const authorize = (data) => {
  return API.get(`/orgs/token/verify?email=${encodeURIComponent(data.email)}`, { 
    headers: {
      Authorization: `Bearer ${data.api_key}`,
    },
  });
};

export const logout = (data) => {
  return API.post(
    `/orgs/token/logout?email=${encodeURIComponent(localStorage.getItem("email"))}`,
    null,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("api_key")}`,
      },
    }
  );
};
