import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers/root.reducer";

const loggerMiddleware = (store) => (next) => (action) => {
  console.log("Dispatching:", action);
  const result = next(action);
  console.log("Next state:", store.getState());
  return result;
};

const store = createStore(
  rootReducer
  // ,
  // applyMiddleware(thunk, loggerMiddleware)
);

export default store;
