//create a component which will check if api_key and email are present in local storage otherwise redirect to login page

import { useEffect, useState } from "react";
import { message } from "antd";

const AuthManager = ({ children }) => {
  const [isLogggedIn, setIsLoggedIn] = useState(false);
  useEffect(() => {
    if (!(localStorage.getItem("api_key") && localStorage.getItem("email"))) {
      setIsLoggedIn(false);
      setTimeout(() => {
        window.location.href = process.env.REACT_APP_REDIRECT_URL + "/company/interviews/async-video";
      }, [1000]);
    } else {
      setIsLoggedIn(true);
    }
  }, []);
  return isLogggedIn ? children : null;
};

export default AuthManager;
