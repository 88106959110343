import React, { useEffect, useState } from "react";
import { Col, Form, Input, message, Row, Typography, Upload } from "antd";
import { UploadOutlined, VideoCameraOutlined } from "@ant-design/icons";
import { createCandidate } from "../../api/candidates";
import { uploadAttachments } from "../../api/questions";
import { useParams } from "react-router-dom";
import { createResponse } from "../../api/responses";
import "./interview.scss";
import VideoRecorderComponent from "../../components/VideoComponents/VideoRecorderComponent";
import { getInterviewQuestions } from "../../api/interviews";

const CandidateResponse = () => {
  const playerRef = React.useRef(null);
  const { uuid } = useParams();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [answers, setAnswers] = useState([]);
  const [interviewInfo, setInterviewInfo] = useState({
    title: "Title Of Interview",
    candidateInfo: false,
    questions: [
      {
        question: "Question here",
        retakes: 2,
        video_url: "/src/documents/blood.mp4",
      },
      {
        question: "Question here",
        retakes: 3,
        video_url: "/src/documents/sample.mp4",
      },
      {
        question: "Question here",
        retakes: 3,
        video_url: "",
      },
    ],
  });

  const submitInterview = () => {
    if (answers.length !== interviewInfo.questions.length) {
      message.error({
        content: "Please answer all questions",
        key: "submitting",
      });
      return;
    }
    let uploadData = new FormData();
    uploadData.append("interview_id", interviewInfo.id);
    uploadData.append("candidate_id", interviewInfo.candidateInfo.id);
    message.loading({
      content: "Submitting Response...",
      key: "submitting",
      duration: 0,
    });
    answers.map((a) => {
      uploadData.append(`files`, a.video, `video-${a.index + 1}`);
    });
    setIsLoading(true);

    uploadAttachments(uploadData)
      .then(({ data }) => {
        message.loading({
          content: "Processing Response...",
          key: "submitting",
          duration: 0,
        });
        let attachments = answers.map((a) => {
          //loop over answers
          let question = interviewInfo.questions.find(
            (q) => q.index === a.index
          );
          //get the question for answer by index
          let answer = data.find((d) => d.question_id === question.id);
          //find the answer from backend
          return {
            question_id: answer.question_id,
            title: answer.question.title,
            answer: answer.url,
            take: a.attempt - 1,
            response_attachment_id: answer.id,
          };
        });
        //return parsed data with answer attempts question and url
        return createResponse(
          {
            response: attachments,
            interview_id: interviewInfo.id,
            candidate_id: interviewInfo.candidateInfo.id,
          },
          interviewInfo.id
        );
      })
      .then(() => {
        message.success({
          content: "Response submitted successfully",
          key: "submitting",
        });
        setIsSubmitted(true);
        setIsLoading(false);
        //go back in route in 5 seconds
      })
      .catch(() => {
        message.error({
          content: "Error submitting Response",
          key: "submitting",
        });
        setIsLoading(false);
      });
  };

  const answerQuestions = (type, video, index) => {
    let tempAnswers = answers;
    tempAnswers.some((a) => a.index === index);
    tempAnswers.find((a) => a.index === index);
    if (type !== "record" && video.size > 104857600) {
      message.error("file too large, expected less than 100mb");
    } else {
      if (tempAnswers.some((a) => a.index === index)) {
        tempAnswers = tempAnswers
          .map((a) =>
            a.index === index ? { ...a, video, attempt: a.attempt + 1 } : a
          )
          .sort((a, b) => a.index - b.index);
      } else {
        tempAnswers = [...tempAnswers, { index: index, video, attempt: 1 }];
        tempAnswers.sort((a, b) => a.index - b.index);
      }
      setAnswers(tempAnswers);
    }
  };
  const [isCandidateSaved, setisCandidateSaved] = useState(false);
  const handleCandidate = (values) => {
    message.loading({
      content: "Starting...",
      key: "createCandidate",
    });
    createCandidate(values)
      .then((res) => {
        message.success({
          content: "Candidate Created",
          key: "createCandidate",
        });
        setInterviewInfo({ ...interviewInfo, candidateInfo: res.data });
      })
      .catch(() => {
        message.error({
          content: "Error Creating Candidate",
          key: "createCandidate",
        });
        setisCandidateSaved(false);
      });
  };

  useEffect(() => {
    setAnswers([]);
    getInterviewQuestions(uuid)
      .then(({ data }) => {
        setInterviewInfo({
          ...interviewInfo,
          ...data[0],
          questions: data[0].questions.map((q, index) => {
            return { ...q, index: index };
          }),
        });
      })
      .catch(() => {});
  }, []);

  return (
    <div className="w-10/12 mx-auto">
      {!isSubmitted && (
        <div className="p-4 bg-white rounded-md">
          <div className="mb-12">
            <Typography.Title level={2} className="font-bold">
              {interviewInfo.candidateInfo
                ? "Candidate Info"
                : "Enter Your Info"}
            </Typography.Title>
          </div>
          <hr className="mb-12" />
          <div className="">
            <Form
              labelCol={{ flex: "110px" }}
              labelAlign="left"
              labelWrap
              wrapperCol={{ flex: 1 }}
              onFinish={handleCandidate}
              disabled={interviewInfo.candidateInfo}
            >
              <Row className="w-full">
                <Col span={24}>
                  <Form.Item
                    name="first_name"
                    label={
                      <div className="my-4 text-base font-bold text-gray-500 ">
                        First Name
                      </div>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Your First Name",
                      },
                    ]}
                  >
                    <Input className="c_input-2" />
                  </Form.Item>
                  <hr className="my-2 mb-4" />

                  <Form.Item
                    name="last_name"
                    label={
                      <div className="my-4 text-base font-bold text-gray-500">
                        Last Name
                      </div>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Your Last Name",
                      },
                    ]}
                  >
                    <Input className="c_input-2 " />
                  </Form.Item>
                  <hr className="my-2 mb-4" />

                  <Form.Item
                    name="email"
                    label={
                      <div className="my-4 text-base font-bold text-gray-500">
                        Email
                      </div>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Your Email",
                      },
                      { type: "email", message: "Please Enter Valid Email" },
                    ]}
                    // required
                  >
                    <Input className="c_input-2 " />
                  </Form.Item>
                  <hr className="my-2 mb-4" />
                  <Form.Item
                    name="phone_number"
                    label={
                      <div className="my-4 text-base font-bold text-gray-500">
                        Contact No
                      </div>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Your Contact No.",
                      },
                      {
                        type: "string",
                        min: 4,
                        max: 15,
                        message: "Contact No. Must be between length 4 & 15",
                      },
                    ]}
                  >
                    <Input className="c_input-2" type="number" />
                  </Form.Item>
                </Col>
              </Row>
              <div className="text-center">
                {!interviewInfo.candidateInfo && (
                  <button
                    type="submit"
                    className="bg-primary-800 mt-4 ml-6 py-2 px-4 rounded-lg text-white text-xl mx-auto"
                  >
                    Submit
                  </button>
                )}
              </div>
            </Form>
          </div>
          {interviewInfo.candidateInfo && (
            <div>
              <hr className="my-4" />
              <Form layout="vertical" onFinish={submitInterview}>
                <div className="my-2">
                  <Typography.Title level={3} className="font-bold">
                    {interviewInfo.title}
                  </Typography.Title>

                  <hr />
                  <ul>
                    {interviewInfo.questions.map((que, i) => (
                      <li key={i}>
                        <QuestionTile
                          que={que}
                          index={i}
                          setVideoData={answerQuestions}
                          videoData={answers.find((a) => a.index === i)}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="mt-7 mx-2">
                  <button
                    type="submit"
                    disabled={isLoading}
                    className="bg-primary-800 rounded py-2 px-4 text-white"
                  >
                    Submit
                  </button>
                </div>
              </Form>
            </div>
          )}
        </div>
      )}
      <div
        className={`text-center ${!isSubmitted} ? 'invisible' : 'visible' ${isSubmitted} ? 'relative' : 'absolute'`}
      >
        <Typography.Title>Response Submitted Successfully</Typography.Title>
      </div>
    </div>
  );
};

const QuestionTile = ({ que, index, setVideoData, videoData }) => {
  const playerRef = React.useRef(null);
  const [isRecording, setIsRecording] = useState(false);
  const [videoType, setVideoType] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [refresh, setRefresh] = useState(true);

  const videoPlayerOptions = {
    autoplay: true,
    controls: true,
    responsive: true,
    fluid: true,
    sources: [
      {
        src: "",
        type: "video/mp4",
      },
    ],
  };

  const recordOptions = {
    controls: true,
    bigPlayButton: false,
    fluid: true,
    plugins: {
      record: {
        audio: true,
        video: true,
        maxLength: 3600,
        debug: true,
      },
    },
    onFinish: (videoData) => {
      setIsRecording(false);
      setVideoData("record", videoData, index);
    },
    onRecordingStart: () => {
      setIsRecording(true);
    },
    que,
    attempts: videoData ? videoData.attempt : 0,
  };
  const props = {
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    showUploadList: false,
    beforeUpload(file) {
      setVideoData("video", file, index);
      setTimeout(() => {
        return false;
      }, 500);
    },
  };

  useEffect(() => {
    if (videoType === "video") {
      setVideoUrl(URL.createObjectURL(videoData.video));
    }
    setRefresh(false);
    setTimeout(() => {
      setRefresh(true);
    }, 100);
  }, [videoData]);

  return (
    <div
      className="flex w-100 bg-white p-4 pl-0 pt-6 pr-8 rounded-md my-2 relative"
      key={index}
    >
      <div className="grow">
        <Row>
          <Col span={24} lg={12} className="mb-4 lg:mb-0">
            <div className="mb-6">
              <div className="text-xl">{que.title}</div>
            </div>
            <div className="flex flex-col lg:flex-row   pt-3">
              <div className="my-4 text-left text-base font-bold text-gray-500 mr-9">
                Answer Type
              </div>
              <div className="flex items-center">
                <button
                  className={`flex item-center justify-center mx-2 py-2 px-4 rounded-md text-white items-center ${videoType} ? 'cursor-no-drop' : 'cursor-pointer'`}
                  type="button"
                  disabled={!!videoType}
                  style={{
                    backgroundColor: "#c04646",
                  }}
                  onClick={() => !videoType && setVideoType("record")}
                >
                  <VideoCameraOutlined className="text-xl mr-2 " />{" "}
                  <div>Record Video</div>
                </button>
                <button
                  className={`bg-primary-800 flex item-center mx-2 py-2 px-4 rounded-md align-middle text-white items-center ${videoType} ? 'cursor-no-drop' : 'cursor-pointer'`}
                  type="button"
                  disabled={!!videoType}
                  onClick={() => !videoType && setVideoType("upload")}
                >
                  <UploadOutlined className="text-xl mr-2" />{" "}
                  <div>Upload Video</div>
                </button>
              </div>
            </div>
            <>
              {videoData && videoData.attempt !== 0 && (
                <Typography.Title className="mt-7" level={5}>
                  You have answered this question
                </Typography.Title>
              )}

              <div className=" text-base font-bold text-gray-500 mt-6">
                Retakes pending :{" "}
                {que && (videoData ? videoData.attempt : 0) !== 0
                  ? que.retakes_allowed -
                    ((videoData ? videoData.attempt : 0) - 1)
                  : que.retakes_allowed}
              </div>
              {que.retakes_allowed -
                ((videoData ? videoData.attempt : 0) - 1) ===
                0 && (
                <>
                  <div style={{ color: "#d46666" }} className="mt-2">
                    You have exhausted all the retakes for this question
                  </div>
                </>
              )}
              {videoType === "upload" && (
                <>
                  <div style={{ color: "#d46666" }} className="mt-6">
                    <span style={{ color: "#d11717" }}>*Note - </span>Maximum
                    file size to upload - 100 MB
                  </div>
                </>
              )}
            </>
          </Col>
          <Col span={24} lg={12}>
            {videoType && (
              <>
                {videoType === "record" ? (
                  <div>
                    <VideoRecorderComponent {...recordOptions} />
                  </div>
                ) : (
                  <div>
                    <Row>
                      <Col lg={6} span={24}>
                        <div className="lg:mt-14">
                          {(videoData ? videoData.attempt : 0) !==
                            que.retakes + 1 && (
                            <Upload
                              {...props}
                              disabled={
                                que.retakes_allowed -
                                  ((videoData ? videoData.attempt : 0) - 1) ===
                                0
                              }
                              accept="video/mp4,video/x-m4v,video/*"
                            >
                              <button
                                className="bg-primary-800 py-2 px-4 mb-4 rounded-md border-2 text-white flex items-center justify-center c_disabled"
                                type="button"
                                disabled={
                                  que.retakes_allowed -
                                    ((videoData ? videoData.attempt : 0) -
                                      1) ===
                                  0
                                }
                              >
                                <div>Upload</div>
                                <UploadOutlined className="ml-2" />
                              </button>
                            </Upload>
                          )}
                          <ul>
                            <li>{videoData ? videoData.video.name : ""}</li>
                          </ul>
                        </div>
                      </Col>
                      <Col lg={18} span={24}>
                        {refresh && (
                          <video width="100%" controls>
                            <source
                              src={
                                videoData
                                  ? URL.createObjectURL(videoData.video)
                                  : ""
                              }
                            />
                          </video>
                        )}
                      </Col>
                    </Row>
                  </div>
                )}
              </>
            )}
          </Col>
        </Row>

        <hr className="mt-4" />
      </div>
    </div>
  );
};

export default CandidateResponse;
