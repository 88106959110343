import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { message, Typography } from "antd";
import { getControls } from "../../api/controls";
import EventHeaderComponent from "../../components/Event/EventHeaderComponent";
import moment from "moment";
import appConstants from "../../documents/constants";

const ControlsPage = () => {
  let navigate = useNavigate();
  const { uuid } = useParams();
  const [controls, setControls] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    setLoading(true);
    message.loading({
      content: "Loading Controls....",
      key: "controls",
      duration: 0,
    });
    getControls(uuid)
      .then(({ data }) => {
        let { results } = data;
        setControls(results);
        setLoading(false);
        message.destroy("controls");
      })
      .catch((err) => {
        message.error({ content: "Error Loading Controls", key: "controls" });
        setLoading(false);
      });
  }, []);

  return (
    <div>
      <div className="max-w-3xl mx-auto py-4 lg:max-w-7xl inline-flex justify-between w-full">
        <button
          className="inline-flex items-center px-4 py-2 rounded-md border border-gray-400 bg-white text-sm font-medium text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:border-gray-400 shadow-sm"
          onClick={() => {
            navigate("/interview");
          }}
        >
          Back
        </button>
      </div>
      <div>
        <EventHeaderComponent />
        <div className="flex items-center justify-between mb-8">
          <Typography.Text className="text-xl font-medium">
            Controls
          </Typography.Text>
        </div>
        <hr className="mb-7" />
        <div className="flex">
          {controls.length > 0 ? (
            <ul className="mb-0 w-full">
              {controls.map((control, i) => (
                <li key={i}>
                  <ControlTimeline event={control} />
                </li>
              ))}
            </ul>
          ) : (
            <div className="mt-4 mx-auto text-xl text-center text-gray-500">
              No Data
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const ControlTimeline = ({ event }) => {
  const [selected, setSelected] = useState("");
  useEffect(() => {
    Object.keys(event).forEach((key) => {
      typeof event[key] === "boolean" && event[key] && setSelected(key);
    });
  }, [event]);
  return (
    <div className="flex w-100 " key={event.id}>
      <div className=" relative -mr-5 w-10" style={{ minHeight: "60px" }}>
        <div
          className="h-100 w-0.5 min-h-full my-0 mx-auto"
          style={{
            backgroundColor: "#85827b",
          }}
        ></div>
        <div
          className="w-10 h-10 absolute my-0 mx-auto flex justify-center items-center text-white"
          style={{
            borderRadius: "50px",
            backgroundColor: appConstants.controls[selected]
              ? appConstants.controls[selected].color
              : "gray",
            top: "10%",
            fontSize: "20px",
          }}
        >
          {appConstants.controls[selected]
            ? appConstants.controls[selected].icon
            : null}
        </div>
      </div>
      <div
        className=" pl-8 pt-2"
        style={{
          paddingBottom: "30px",
        }}
      >
        <div className="text-xl mb-2">
          <span>
            <b>{event.participant.username}</b>
          </span>{" "}
          {appConstants.controls[selected]
            ? appConstants.controls[selected].key
            : selected}
        </div>
        <div>{moment(event.created).format("lll")}</div>
      </div>
    </div>
  );
};

export default ControlsPage;
