import React from "react";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Row,
  Select,
  Typography,
} from "antd";
import "./interview.scss";
import { useNavigate } from "react-router-dom";
import appConstants from "../../documents/constants";
import { publishInterview } from "../../api/interviews";
import { CustomInput, errorValidator } from "../../utilities";

const InterviewCreatePage = () => {
  let navigate = useNavigate();
  const onFinish = (values) => {
    // return ;
    message.loading({ content: "Loading...", key: "interview" });
    publishInterview({ ...values, is_active: true, is_deleted: false })
      .then(() => {
        message.success({
          key: "interview",
          content: "Async Interview Created Successfully",
          duration: "2",
        });
        setTimeout(() => {
          navigate("/interview");
        }, 2000);
      })
      .catch(() => {
        message.error({
          key: "interview",
          content: "Async Interview Creation Failed",
        });
      });
  };

  const [form] = Form.useForm();
  return (
    <>
      <div className="max-w-3xl mx-auto py-4 lg:max-w-7xl inline-flex justify-between w-full">
        <button
          className="inline-flex items-center px-4 py-2 rounded-md border border-gray-400 bg-white text-sm font-medium text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:border-gray-400 shadow-sm"
          onClick={() => {
            navigate("/interview");
          }}
        >
          Back
        </button>
      </div>
      <Row justify="center">
        <Col span={24} lg={18} md={18}>
          <div>
            <div className="mb-5">
              <Typography.Title level={1} className="font-bold">
                Create Async Interview
              </Typography.Title>
            </div>
            <hr className="mt-3" />
            <Form
              onChange={(e) => {}}
              name="dynamic_form_nest_item"
              onFinish={onFinish}
              form={form}
              autoComplete="off"
              layout="vertical"
            >
              <Form.Item
                name="title"
                label={
                  <div className="my-6 text-xl">Async Interview Title</div>
                }
                rules={[
                  {
                    required: true,
                    message: "Please Enter Async Interview Title",
                  },
                ]}
              >
                <Input
                  className="c_input"
                  style={{
                    borderRadius: "10px",
                  }}
                  placeholder="Enter Async Interview Title"
                />
              </Form.Item>
              <hr className="mt-7" />
              <Form.List name="questions">
                {(fields, { add, remove }) => (
                  <ul>
                    {fields.map(({ key, name, ...restField }, i) => (
                      <ListItem
                        meta={{ key, name, ...restField }}
                        i={i}
                        remove={remove}
                        key={i}
                      />
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed add_que_botton "
                        size="large"
                        className="rounded-md my-4 ml-2"
                        onClick={() => add()}
                        block
                      >
                        <PlusOutlined className="-mt-2 " />
                        Add Question
                      </Button>
                    </Form.Item>
                  </ul>
                )}
              </Form.List>
              <Form.Item>
                <div className="flex ">
                  <button
                    className={
                      "mx-2 w-1/2 py-2 px-4 text-xl text-white rounded-lg"
                    }
                    type="reset"
                    style={{
                      backgroundColor: "rgb(156 163 175)",
                    }}
                  >
                    Reset
                  </button>
                  <button
                    className={
                      "bg-primary-800 mx-2 w-1/2 py-2 px-4 text-xl text-white rounded-lg "
                    }
                    type="submit"
                  >
                    Publish
                  </button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </Col>
      </Row>
    </>
  );
};

const ListItem = ({ meta, i, remove }) => {
  const [type, setType] = React.useState("video");
  let { key, name, ...restField } = meta;

  return (
    <li key={i}>
      <div className="flex w-100 bg-white  pt-6  rounded-md my-4 relative">
        <div className="grow">
          <div className="py-2 text-xl mb-3">Question {i + 1}</div>
          <Form.Item
            {...restField}
            initialValue=""
            name={[name, "title"]}
            rules={[{ validator: errorValidator }]}
          >
            <CustomInput />
          </Form.Item>
          <Row className="mt-10" gutter={10}>
            <Col span={8}>
              <div className="flex flex-col">
                <div className="c_label mb-4">Retakes</div>
                <Row className="grow">
                  <div className="grow">
                    <Form.Item
                      {...restField}
                      initialValue={1}
                      name={[name, "retakes_allowed"]}
                      rules={[
                        {
                          required: true,
                          message: "Please Select No. of Retakes Allowed",
                        },
                      ]}
                    >
                      <Select className=" w-full rounded">
                        {appConstants.Interview.QuestionsRetakes.map((item) => (
                          <Select.Option key={item.label} value={item.value}>
                            {item.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </Row>
              </div>
            </Col>
            <Col span={8}>
              <div className="flex flex-col ">
                <div className="c_label mb-4">Type</div>
                <Row className="grow">
                  <div className="grow ">
                    <Form.Item
                      {...restField}
                      initialValue="video"
                      name={[name, "response_type"]}
                      rules={[
                        {
                          required: true,
                          message: "Please Select The Response Type",
                        },
                      ]}
                    >
                      <Select
                        className=" w-full rounded"
                        onSelect={(value) => setType(value)}
                      >
                        {appConstants.Interview.QuestionsType.map((item) => (
                          <Select.Option key={item.label} value={item.value}>
                            {item.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </Row>
              </div>
            </Col>
            {type === "video" && (
              <Col span={8}>
                <div className="flex flex-col ">
                  <div className="c_label mb-4">Duration</div>
                  <Row className="grow">
                    <div className="grow">
                      <Form.Item
                        {...restField}
                        initialValue={"00:01:00"}
                        name={[name, "video_duration"]}
                        rules={[
                          {
                            required: true,
                            message: "Please Select The Response Duration",
                          },
                        ]}
                      >
                        <Select className=" w-full rounded">
                          {appConstants.Interview.QuestionsDurations.map(
                            (item) => (
                              <Select.Option
                                key={item.label}
                                value={item.value}
                              >
                                {item.label}
                              </Select.Option>
                            )
                          )}
                        </Select>
                      </Form.Item>
                    </div>
                  </Row>
                </div>
              </Col>
            )}
          </Row>
        </div>
        <div className="absolute top-0 right-0">
          <MinusCircleOutlined onClick={() => remove(name)} />{" "}
        </div>
      </div>
    </li>
  );
};

export default InterviewCreatePage;
