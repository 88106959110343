import interviewActions from "../../redux/actions/interview.actions";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input } from "antd";
import { FieldTimeOutlined, PoweroffOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useRecordWebcam, CAMERA_STATUS } from "react-record-webcam";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

let interval;
const QuestionComponent = () => {
  const {
    questions,
    currentQuestion,
    candidate,
    id,
    isBackFromPreview,
    tempDemoQuestions,
    isDemo,
  } = useSelector((state) => state.interview);
  const interview = useSelector((state) => state.interview);
  const [question, setQuestion] = useState(null);
  const [isTouched, setIsTouched] = useState(false);
  const [status, setStatus] = useState("idle");
  const [text, setText] = useState("");
  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    //got to the useEffect
    if (isDemo === true) {
      setQuestion(tempDemoQuestions[currentQuestion]);
    } else {
      setQuestion(questions[currentQuestion]);
    }
  }, [questions, currentQuestion, tempDemoQuestions]);

  useEffect(() => {
    if (!isDemo) {
      if (questions[currentQuestion].response_type === "text") {
        //back from preview, setting text
        setText(questions[currentQuestion].answer);
      } else {
        setText("");
      }
      setIsTouched(false);
    }
  }, [currentQuestion]);

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { uuid } = useParams();
  const recordWebcam = useRecordWebcam({
    filename: "test-filename",
    fileType: "video/webm",
  });

  const [remainingTime, setRemainingTime] = useState(null);

  const handleAnswerSubmit = (values) => {
    //Submitting answer
    interviewActions.answerQuestion(uuid, question.id, values.answer)(dispatch);
  };

  const handleAnswerQuestion = (values) => {
    //Answering question
    if (question.response_type === "text") {
      interviewActions.answerQuestion(
        currentQuestion,
        question.response_type,
        question.id,
        candidate.id,
        id,
        text
      )(dispatch);
    }
  };
  const handleAnswerUpload = () => {
    //Uploading answer
    if (question.response_type === "text") {
      interviewActions.uploadAnswer(
        currentQuestion,
        question.response_type,
        question.id,
        candidate.id,
        id,
        text,
        (index) => {
          setTimeout(() => {
            if (index - 1 !== questions.length) handleNextQuestion(+index);
          }, 0);
        }
      )(dispatch);
    } else if (question.response_type === "video") {
      interviewActions.uploadAnswer(
        currentQuestion,
        question.response_type,
        question.id,
        candidate.id,
        id,
        null,
        (index) => {
          setTimeout(() => {
            if (index - 1 !== questions.length) handleNextQuestion(+index);
          }, 0);
        }
      )(dispatch);
    }
  };

  const handleDemoExist = () => {
    interviewActions.resetDemo(interview)(dispatch);
  };

  const handleNextQuestion = (values) => {
    //Next question
    if (isBackFromPreview) {
      interviewActions.setCurrentQuestion(-1)(dispatch);
      return;
    }
    if (currentQuestion < questions.length - 1) {
      interviewActions.setCurrentQuestion(currentQuestion + 1)(dispatch);
      if (question.response_type === "video") {
        recordWebcam.close();
      }
    }
  };

  const handleAnswerQuestionWithRetake = (values) => {
    //Answering question with retakes
    interviewActions.retakeQuestion(question.id, isDemo)(dispatch);
  };

  const { TextArea } = Input;

  const videoDurationParser = (duration) => {
    // 00:00:00 to seconds
    const time = duration.split(":");
    const seconds =
      parseInt(time[0]) * 60 * 60 + parseInt(time[1]) * 60 + parseInt(time[2]);
    return seconds;
  };
  const handleStartRecording = () => {
    //Starting recording
    setCountDown(null);
    recordWebcam.start();
    createTimer(videoDurationParser(question.video_duration) * 1000);
  };

  const handleReUpload = () => {
    setStatus("uploading");
    recordWebcam
      .getRecording()
      .then((recording) => {
        interviewActions.answerQuestion(
          currentQuestion,
          question.response_type,
          question.id,
          candidate.id,
          id,
          recording,
          (isLoading) => {
            isLoading === true && setStatus("uploading");
          },
          () => {
            setStatus("uploaded");
          },
          (error) => {
            setStatus("failed");
          },
          interview
        )(dispatch, true);
      })
      .catch((error) => {});
  };

  const handleStopRecording = () => {
    //Stopping recording & timer
    recordWebcam.stop();
    clearInterval(interval);
    setRemainingTime(null);
    setStatus("uploading");
    setTimeout(() => {
      recordWebcam
        .getRecording()
        .then((recording) => {
          interviewActions.answerQuestion(
            currentQuestion,
            question.response_type,
            question.id,
            candidate.id,
            id,
            recording,
            (isLoading) => {
              isLoading === true && setStatus("uploading");
            },
            () => {
              setStatus("uploaded");
            },
            (error) => {
              setStatus("failed");
            },
            interview
          )(dispatch);
        })
        .catch((error) => {});
    }, 5000);
  };

  const timeStringify = (time) => {
    let hours = Math.floor(time / 3600);
    let minutes = Math.floor((time % 3600) / 60);
    let seconds = time % 60;
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  const createTimer = (time) => {
    let localTime = +JSON.parse(JSON.stringify(time / 1000));
    setRemainingTime(timeStringify(localTime));
    interval = setInterval(() => {
      if (localTime <= 0) {
        handleStopRecording();
      }
      setRemainingTime(timeStringify(localTime));
      localTime--;
    }, 1000);
  };

  const countDownHandler = (time) => {
    //create sound on countdown
    let tick = new Audio("/tick.mp3");
    let localTime = +JSON.parse(JSON.stringify(time / 1000));
    setCountDown(localTime);
    interval = setInterval(() => {
      if (localTime > 0) {
        tick.currentTime = 0;
        tick.play();
      }
      if (localTime < 1) {
        clearInterval(interval);
        handleStartRecording();
        setCountDown(null);
      }
      setCountDown(localTime);

      localTime--;
    }, 1000);
  };

  useEffect(() => {
    if (
      question &&
      question.response_type === "video" &&
      question.status === "pending" &&
      (recordWebcam.status === "INIT" || recordWebcam.status === "CLOSED")
    ) {
      recordWebcam.open();
    }
  }, [question]);

  useEffect(() => {
    return () => {
      clearInterval(interval);
      recordWebcam.close();
    };
  }, []);

  const [cameraStatus, setCameraStatus] = useState(CAMERA_STATUS);
  const [countDown, setCountDown] = useState(null);

  return (
    <div style={{ minHeight: "60vh" }} className="flex items-center">
      {question && (
        <>
          {question.response_type === "text" ? (
            <div className="w-full flex flex-col space-x-0 space-y-2  items-center my-5">
              <div className="w-full md:w-1/2 flex flex-col  grow self-stretch">
                <div>
                  <p className="text-gray-600 text-base font-semibold flex  items-center">
                    <i className="fa-solid fa-message mr-2" />
                    <span className="ml-1">
                      Question {currentQuestion + 1}{" "}
                    </span>
                  </p>
                  <div
                    className="custom-markdown text-black text-lg font-semibold"
                    dangerouslySetInnerHTML={{
                      __html: question.title,
                    }}
                  />
                </div>
              </div>
              <div className="w-full" id="textEditor">
                <SearchInput
                  theme="snow"
                  value={text}
                  onChange={setText}
                  placeholder={"Enter you answer here..."}
                  readOnly={question.status !== "pending"}
                  questionId={question.id}
                />
                {isTouched && text.trim().length === 0 && (
                  <small className="text-rose-800">
                    Please Enter the answer to submit
                  </small>
                )}

                <div className="block mt-8">
                  <div className="flex flex-col md:flex-row space-y-8 md:space-y-0 space-x-0 md:space-x-4 w-full">
                    {question.status === "pending" && (
                      <button
                        className="bg-primary-800 py-2 px-12 rounded-full text-xl cursor-pointer disabled:cursor-not-allowed text-white w-full md:w-1/4"
                        onClick={() => {
                          setIsTouched(true);
                          handleAnswerQuestion();
                        }}
                        disabled={
                          !(
                            text &&
                            text.length !== 0 &&
                            text.replace(/<(.|\n)*?>/g, "").trim().length !== 0
                          )
                        }
                      >
                        Submit
                      </button>
                    )}
                    {(question.status === "submitted" ||
                      question.status === "uploading") && (
                      <>
                        <div className=" relative w-full md:w-1/2">
                          <button
                            className="bg-primary-800 py-2 px-12 rounded-full text-xl cursor-pointer disabled:cursor-not-allowed text-white w-full"
                            onClick={handleAnswerQuestionWithRetake}
                            disabled={
                              question.retakes_used >=
                                question.retakes_allowed ||
                              question.status === "uploading"
                            }
                          >
                            <i className="fa-solid fa-rotate-right mr-2"></i>
                            Edit
                          </button>
                          <small className="absolute left-0 bottom-0 text-center w-full -mb-6  text-sm text-gray-500">
                            Edits Pending :{" "}
                            {question.retakes_used
                              ? question.retakes_allowed - question.retakes_used
                              : question.retakes_allowed}
                          </small>
                        </div>

                        <button
                          className="bg-primary-800 py-2 px-12 rounded-full text-xl cursor-pointer disabled:cursor-not-allowed text-white
                                                    w-full md:w-1/2"
                          onClick={handleAnswerUpload}
                          disabled={question.status === "uploading"}
                        >
                          Save and Continue
                        </button>
                      </>
                    )}
                    {question.status === "uploaded" && (
                      <button
                        className="bg-primary-800 py-2 px-12 rounded-full text-xl cursor-pointer disabled:cursor-not-allowed text-white w-full md:w-1/4"
                        onClick={handleNextQuestion}
                      >
                        Next Question
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="w-full flex flex-col md:flex-row space-x-0 space-y-2 md:space-x-4 md:space-y-0 items-center my-5">
              <div className="w-full md:w-1/2 flex flex-col justify-start grow self-stretch">
                <div>
                  <p className="text-gray-600 text-base font-semibold flex  items-center">
                    <i className="fa-solid fa-message mr-2" />
                    <span className="ml-1">
                      Question {currentQuestion + 1}{" "}
                    </span>
                  </p>
                  <div
                      className="custom-markdown text-black text-lg font-semibold"
                    dangerouslySetInnerHTML={{
                      __html: question.title,
                    }}
                  />
                  <p className="text-gray-500 text-base font-semibold">
                    {recordWebcam.status === "RECORDING"
                      ? `Answer Time :${remainingTime}`
                      : `Answer Time : ${question?.video_duration}`}
                  </p>
                </div>
                <div className="hidden md:block">
                  <div className="flex space-x-2 mt-8 items-center justify-start">
                    {question.status === "pending" && (
                      <>
                        {(recordWebcam.status === "OPEN" ||
                          recordWebcam.status === "CLOSED" ||
                          recordWebcam.status === "INIT") && (
                          <button
                            className="text-center bg-primary-800 py-2 px-12 rounded-full text-xl cursor-pointer disabled:cursor-not-allowed text-white w-1/2 flex items-center justify-center"
                            onClick={() => countDownHandler(3000)}
                            disabled={
                              recordWebcam.status === "INIT" ||
                              recordWebcam.status === "CLOSED" ||
                              countDown > 0
                            }
                          >
                            <i className="fa-solid fa-video mr-2"></i>
                            <span>Record Now</span>
                          </button>
                        )}
                        {recordWebcam.status === "RECORDING" && (
                          <button
                            className="text-center bg-red-400 py-2 px-12 rounded-full text-xl cursor-pointer disabled:cursor-not-allowed text-white w-1/2"
                            onClick={handleStopRecording}
                          >
                            Stop Record
                          </button>
                        )}
                        {(status === "uploading" || status === "failed") && (
                          <button
                            disabled={status === "uploading"}
                            className="bg-primary-800 py-2 px-12 rounded-full text-xl cursor-pointer disabled:cursor-not-allowed text-white w-full"
                            onClick={handleReUpload}
                          >
                            {status === "failed" && "Retry "}Loading...
                          </button>
                        )}
                      </>
                    )}
                    {(question.status === "submitted" ||
                      question.status === "uploading") && (
                      <>
                        <div className="relative w-1/2">
                          <button
                            className="text-center bg-primary-800 py-2 px-12 rounded-full text-xl cursor-pointer disabled:cursor-not-allowed text-white w-full"
                            onClick={() => {
                              recordWebcam.retake();
                              handleAnswerQuestionWithRetake();
                            }}
                            disabled={
                              question.retakes_used >=
                                question.retakes_allowed ||
                              status === "uploading"
                            }
                          >
                            <i className="fa-solid fa-rotate-right mr-2"></i>
                            Retake
                          </button>
                          <small className=" absolute left-0 bottom-0 text-center w-full -mb-6 text-sm text-gray-500">
                            Retakes Pending :{" "}
                            {question.retakes_used
                              ? question.retakes_allowed - question.retakes_used
                              : question.retakes_allowed}
                          </small>
                        </div>

                        <button
                          className="text-center bg-primary-800 py-2 px-12 rounded-full text-xl cursor-pointer disabled:cursor-not-allowed text-white w-1/2"
                          onClick={
                            isDemo ? handleDemoExist : handleAnswerUpload
                          }
                          disabled={status === "uploading"}
                        >
                          Save and Continue
                        </button>
                      </>
                    )}
                    {question.status === "uploaded" && (
                      <button
                        className="text-center bg-primary-800 py-2 px-12 rounded-full text-xl cursor-pointer disabled:cursor-not-allowed text-white "
                        onClick={handleNextQuestion}
                      >
                        Next Question
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div className="w-full md:w-1/2">
                <div className="aspect-video relative bg-black rounded-xl flex justify-center items-center overflow-hidden">
                  {status === "uploading" && (
                    <div className="text-white">Loading Video...</div>
                  )}
                  {recordWebcam.status === "CLOSED" ? (
                    question.status === "submitted" ||
                    question.status === "uploaded" ? (
                      <video
                        src={question.answer}
                        className={`aspect-video w-full block object-cover`}
                        controls
                      />
                    ) : (
                      <div className="aspect-video w-full">
                        <span className="text-white text-base ml-auto absolute top-2 right-2 rounded-full bg-gray-600 px-4 flex items-center py-1">
                          <PoweroffOutlined className="text-red-500 mr-2" />
                          Offline
                        </span>
                      </div>
                    )
                  ) : (
                    <>
                      {(recordWebcam.status === "OPEN" ||
                        recordWebcam.status === "INIT" ||
                        recordWebcam.status === "RECORDING") && (
                        <>
                          <span
                            className="text-white text-base ml-auto absolute top-2 right-2 rounded-full px-4 flex items-center py-1"
                            style={{
                              background: "rgba(255,255,255,0.4)",
                            }}
                          >
                            <i
                              className={`fa-solid fa-circle text-${
                                recordWebcam.status === "RECORDING" ? "red" : ""
                              }${
                                recordWebcam.status === "OPEN" ? "green" : ""
                              }-500 mr-2`}
                            />
                            {recordWebcam.status === "OPEN" && "Ready"}
                            {recordWebcam.status === "INIT" && "Starting"}
                            {recordWebcam.status === "RECORDING" && "Recording"}
                          </span>
                          {recordWebcam.status === "RECORDING" &&
                            remainingTime !== "00:00:00" && (
                              <span
                                className="text-white text-base ml-auto absolute top-2 left-2 rounded-full px-4 flex items-center py-1"
                                style={{
                                  background: "rgba(255,255,255,0.4)",
                                }}
                              >
                                <FieldTimeOutlined className="text-white mr-2" />
                                {remainingTime}
                              </span>
                            )}
                        </>
                      )}
                      <div
                        className={`text-white text-5xl top-2/4 left-1/2 absolute p-4 w-24 h-24 rounded-full text-center ${
                          countDown ? "flex" : "hidden"
                        } items-center justify-center`}
                        style={{
                          transform: "translate(-50%,-50%)",
                          background: "rgba(255,255,255,0.4)",
                        }}
                      >
                        <span>{countDown}</span>
                      </div>

                      <video
                        ref={recordWebcam.webcamRef}
                        className={`aspect-video w-full object-cover ${
                          question.status === "pending" &&
                          (recordWebcam.status === "OPEN" ||
                            recordWebcam.status === "INIT" ||
                            recordWebcam.status === "RECORDING")
                            ? "block"
                            : "hidden"
                        }`}
                        autoPlay
                        muted
                      />
                      <video
                        src={question.answer}
                        className={`aspect-video w-full object-cover ${
                          question.status === "pending" &&
                          (recordWebcam.status === "OPEN" ||
                            recordWebcam.status === "INIT" ||
                            recordWebcam.status === "RECORDING" ||
                            status === "uploading")
                            ? "hidden"
                            : "block"
                        }`}
                        controls
                      />
                    </>
                  )}
                </div>
                <div className="block md:hidden">
                  <div className="flex flex-col space-y-2 mt-8 items-center justify-center">
                    {question.status === "pending" && (
                      <>
                        {(recordWebcam.status === "OPEN" ||
                          recordWebcam.status === "CLOSED" ||
                          recordWebcam.status === "INIT") && (
                          <button
                            className="bg-primary-800 py-2 px-12 rounded-full text-xl cursor-pointer disabled:cursor-not-allowed text-white w-full flex items-center justify-center"
                            onClick={() => countDownHandler(3000)}
                            disabled={
                              recordWebcam.status === "INIT" ||
                              recordWebcam.status === "CLOSED" ||
                              countDown > 0
                            }
                          >
                            <i className="fa-solid fa-video mr-2"></i>
                            <span>Record Now</span>
                          </button>
                        )}
                        {recordWebcam.status === "RECORDING" && (
                          <button
                            className="bg-red-400 py-2 px-12 rounded-full text-xl cursor-pointer disabled:cursor-not-allowed text-white w-full"
                            onClick={handleStopRecording}
                          >
                            Stop Record
                          </button>
                        )}
                        {(status === "uploading" || status === "failed") && (
                          <button
                            disabled={status === "uploading"}
                            className="bg-primary-800 py-2 px-12 rounded-full text-xl cursor-pointer disabled:cursor-not-allowed text-white w-full"
                            onClick={handleReUpload}
                          >
                            {status === "failed" && "Retry "}Loading...
                          </button>
                        )}
                      </>
                    )}
                    {(question.status === "submitted" ||
                      question.status === "uploading") && (
                      <>
                        <div className="relative w-full mb-8">
                          <button
                            className="bg-primary-800 py-2 px-12 rounded-full text-xl cursor-pointer disabled:cursor-not-allowed text-white w-full"
                            onClick={() => {
                              recordWebcam.retake();
                              handleAnswerQuestionWithRetake();
                            }}
                            disabled={
                              question.retakes_used >=
                                question.retakes_allowed ||
                              question.status === "uploading"
                            }
                          >
                            <i className="fa-solid fa-rotate-right mr-2"></i>
                            Retake
                          </button>
                          <small className=" absolute left-0 bottom-0 text-center w-full -mb-8 md:-md-12 text-sm text-gray-500">
                            Retakes Pending :{" "}
                            {question.retakes_used
                              ? question.retakes_allowed - question.retakes_used
                              : question.retakes_allowed}
                          </small>
                        </div>

                        <button
                          className="bg-primary-800 py-2 px-12 rounded-full text-xl cursor-pointer disabled:cursor-not-allowed text-white w-full"
                          onClick={
                            isDemo ? handleDemoExist : handleAnswerUpload
                          }
                          disabled={question.status === "uploading"}
                        >
                          Save and Continue
                        </button>
                      </>
                    )}
                    {question.status === "uploaded" && (
                      <button
                        className="bg-primary-800 py-2 px-12 rounded-full text-xl cursor-pointer disabled:cursor-not-allowed text-white "
                        onClick={handleNextQuestion}
                      >
                        Next Question
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

const TextAnswer = ({ text, setText, disabled }) => {
  return (
    <ReactQuill
      theme="snow"
      value={text}
      onChange={setText}
      disabled={disabled}
    />
  );
};

function SearchInput(props) {
  const { value, questionId } = props;
  const [debouncedValue, setDebouncedValue] = useState(value);
  const dispatch = useDispatch();

  useEffect(
    () => {
      // Set debouncedValue to inputValue after 500ms delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, 500);

      // Cancel the timeout if inputValue changes
      return () => {
        clearTimeout(handler);
      };
    },
    [value] // Re-run effect when inputValue changes
  );

  useEffect(
    () => {
      // Do something with debouncedValue
      interviewActions.answerQuestionTemp(questionId, debouncedValue)(dispatch);
      // this is where you could call a search function or similar
    },
    [debouncedValue] // Only re-run effect if debounced search term changes
  );

  return <ReactQuill {...props} />;
}
export default QuestionComponent;
