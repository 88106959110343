import API from "./axios";

export const getResponses = (interview_id, search) => {
  return API.get(`/interviews/response/`, {
    params: {
      interview_id: interview_id,
      search: search,
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem("api_key")}`,
    },
  });
};

export const createResponse = (data, interview_id) => {
  return API.post("/interviews/response/", data, {
    params: {
      interview_id: interview_id,
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem("api_key")}`,
    },
  });
};
export const deleteResponse = (id, data) => {
  return API.delete(`/interviews/response/${id}`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("api_key")}`,
    },
  });
};

export const getResponse = (id, interview_id) => {
  return API.get(`/interviews/response/${id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("api_key")}`,
    },
  });
};

// /interviews/response/?uuid=1d03b75b-b674-4254-a476-7f835498fb82
export const getResponseByUUID = (uuid) => {
  return API.get(`/interviews/response/${uuid}/`);
};
