import { message, Typography } from "antd";
import React from "react";
import { getAttachments } from "../../api/attachments";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import EventHeaderComponent from "../../components/Event/EventHeaderComponent";

const AttachmentsPage = () => {
  let navigate = useNavigate();
  const [attachments, setAttachments] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const { uuid } = useParams();

  React.useEffect(() => {
    setLoading(true);
    message.loading({
      content: "Loading attachments...",
      duration: 0,
      key: "attachments",
    });
    getAttachments(uuid)
      .then(({ data }) => {
        setAttachments(data.results);
        setLoading(false);
        message.destroy("attachments");
      })
      .catch((error) => {
        message.error({
          content: "Error loading attachments",
          key: "attachments",
        });
      });
  }, []);

  return (
    <div>
      <div className="max-w-3xl mx-auto py-4 lg:max-w-7xl inline-flex justify-between w-full">
        <button
          className="inline-flex items-center px-4 py-2 rounded-md border border-gray-400 bg-white text-sm font-medium text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:border-gray-400 shadow-sm"
          onClick={() => {
            navigate("/interview");
          }}
        >
          Back
        </button>
      </div>
      <div>
        <EventHeaderComponent />
        <div className="flex items-center justify-between mb-8">
          <Typography.Text className="text-xl font-medium">
            Attachments
          </Typography.Text>
        </div>
        <hr className="mb-7" />
        {attachments && attachments.length ? (
          <div
            className="m-0 border-2 rounded-lg px-2 pt-1 -mx-4 divide-gray-200"
            style={{
              borderColor: "rgba(180, 180, 180, 0.5)",
            }}
            id="custom-table"
          >
            <ul>
              {attachments.map((attachment, i) => (
                <li key={i}>
                  <div className="flex items-center justify-between p-2 w-full mb-2">
                    <div className="flex  w-full">
                      <div className="flex flex-col flex-grow">
                        <div className="text-lg mb-4">{attachment.title}</div>
                        <div className="text-sm text-gray-500">
                          {moment(attachment.created).format("lll")}
                        </div>
                      </div>
                      <div className="flex items-center justify-center">
                        <video
                          controls={true}
                          src={attachment.url}
                          alt="attachment"
                          className=" w-80 rounded-lg"
                        />
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <div className="mt-4 mx-auto text-xl text-center text-gray-500">
            No attachments
          </div>
        )}
      </div>
    </div>
  );
};

export default AttachmentsPage;
