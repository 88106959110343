import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useWindowSize from "react-use/lib/useWindowSize";
import {
  Col,
  Form,
  Input,
  List,
  message,
  Modal,
  Pagination,
  Row,
  Typography,
} from "antd";
import interviewActions from "../../redux/actions/interview.actions";
import QuestionComponent from "../../components/Question/QuestionComponent";
import {
  InterviewGettingStartedPage,
  ShareComponent,
} from "./InterviewGettingStartedPage";
import appConstants from "../../documents/constants";
import Confetti from "react-confetti";
import { checkCameraSupport, handleConcat } from "../../utilities";

const InterviewPage = () => {
  const interview = useSelector((state) => state.interview);
  const { uuid } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [interviewStatus, setInterviewStatus] = useState("idle");
  const [interviewStatusMessage, setInterviewStatusMessage] = useState("");
  const [tempCandidate, setTempCandidate] = useState({});

  useEffect(() => {
    interviewActions.getInterview(
      uuid,
      (isLoading) => {
        isLoading && setInterviewStatus("loading");
      },
      (candidate) => {
        setInterviewStatus("success");
        setTempCandidate(candidate);
      },
      (error) => {
        setInterviewStatus("error");
        setInterviewStatusMessage(error);
      },
      interview
    )(dispatch, window.location.href, true);
  }, [uuid]);

  useEffect(() => {
    const url = new URL(window.location.href);

    if (interview.candidate) {
      navigate("/async-interview/" + interview.uuid + "/test");
    }
  }, [interview]);

  useMemo(() => {
    if (interview.candidate && interview.uuid) {
      window.localStorage.setItem("session", JSON.stringify(interview));
    }
  }, [interview]);
  if (interviewStatus === "loading") {
    return (
      <div className="flex w-full justify-center items-center min-h-[500px]">
        <p className="text-lg text-center">Loading...</p>
      </div>
    );
  } else if (interviewStatus === "error") {
    return (
      <div className="flex w-full justify-center items-center min-h-[500px] text-red-500">
        <p className="text-lg text-center">{interviewStatusMessage}</p>
      </div>
    );
  } else {
    return interview?.candidate?.id ? (
      <div>
        <div>
          <Typography.Title level={3}>{interview?.name}</Typography.Title>
        </div>
        <div>
          <QuestionList />
        </div>
      </div>
    ) : (
      <IntroComponent interview={interview} tempCandidate={tempCandidate} />
    );
  }
};

const IntroComponent = ({ interview, tempCandidate }) => {
  const dispatch = useDispatch();

  const handleCandidateSubmit = (values) => {
    checkCameraSupport()
      .then(() => {
        interviewActions.createCandidate(values)(dispatch);
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  return (
    <div>
      <div className="flex flex-col space-y-4 space-x-0 md:flex-row md:space-y-0 md:space-x-4 mt-12">
        <div className="w-full md:w-1/2 md:-ml-6 mr-28">
          <InterviewGettingStartedPage interview={interview} />
        </div>
        <div className="p-4 bg-white rounded-md w-full md:w-1/2">
          <div className="mb-8 text-center md:text-start">
            <Typography.Title level={1} className="font-bold mb-6 text-2xl">
              Your Details
            </Typography.Title>
            <Typography.Text className="text-gray-500 text-lg">
              Please provide your details so we can contact you
            </Typography.Text>
          </div>
          <div className="">
            <Form
              // labelCol={{flex: '110px'}}
              labelAlign="left"
              labelWrap
              layout={"vertical"}
              wrapperCol={{ flex: 1 }}
              initialValues={tempCandidate}
              onFinish={handleCandidateSubmit}
            >
              <Row className="w-full space-y-4">
                <Col span={24}>
                  <Row gutter={10}>
                    <Col span={12}>
                      <Form.Item
                        name="first_name"
                        label={
                          <div className="text-base font-semibold text-gray-500 ">
                            First Name
                          </div>
                        }
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Your First Name",
                          },
                        ]}
                      >
                        <Input
                          disabled={tempCandidate?.first_name}
                          className="c_input-2"
                          placeholder="first name"
                          prefix={<i className="fa-solid fa-user mr-2"></i>}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="last_name"
                        label={
                          <div className="text-base font-semibold text-gray-500">
                            Last Name
                          </div>
                        }
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Your Last Name",
                          },
                        ]}
                      >
                        <Input
                          disabled={tempCandidate?.last_name}
                          className="c_input-2 "
                          placeholder="last name"
                          prefix={<i className="fa-solid fa-user mr-2"></i>}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>

                <Col span={24}>
                  <Row gutter={10}>
                    <Col span={12}>
                      <Form.Item
                        name="email"
                        label={
                          <div className="text-base font-semibold text-gray-500">
                            Email
                          </div>
                        }
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Your Email Id",
                          },
                          {
                            type: "email",
                            message: "Please Enter Valid Email Id",
                          },
                        ]}
                        // required
                      >
                        <Input
                          disabled={tempCandidate?.email}
                          className="c_input-2 "
                          placeholder="email"
                          prefix={<i className="fa-solid fa-envelope mr-2"></i>}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="phone_number"
                        label={
                          <div className="text-base font-semibold text-gray-500">
                            Phone Number
                          </div>
                        }
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Your Phone No.",
                          },
                          {
                            type: "string",
                            min: 4,
                            max: 15,
                            message: "Phone No. Must be between length 4 & 15",
                          },
                        ]}
                      >
                        <Input
                          disabled={tempCandidate?.phone_number}
                          className="w-full c_input-2"
                          type="number"
                          placeholder="phone number"
                          prefix={<i className="fa-solid fa-phone mr-2"></i>}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <div className="flex flex-col justify-around">
                <div className="flex flex-col items-center gap-2">
                  <button
                    type="submit"
                    className="py-2 px-7 bg-primary-800 rounded-full text-xl cursor-pointer text-white"
                  >
                    Begin
                  </button>
                  <small className="text-sm font-semibold text-gray-500">
                    Proceed to the 1st question
                  </small>
                </div>
              </div>
            </Form>
          </div>
          <ShareComponent />
        </div>
      </div>
    </div>
  );
};

const OutroComponent = ({ title, org }) => (
  <div className="flex flex-col items-center">
    <img src={"/check.gif"} className="h-36 w-36 mb-4" alt="empty" />
    <Typography.Title level={3}>{title}</Typography.Title>
    <Typography.Title level={3} className="my-2">
      {org || "Tacnique"}
    </Typography.Title>
    <Typography.Title level={3} className="text-gray-900 text-center">
      You're all done
    </Typography.Title>
    <Typography.Text className="text-gray-500 text-center text-base my-4">
      Thank you for taking the time to submit the answers today. <br />
      Enjoy the rest of your day!
    </Typography.Text>
    <button
      className="py-4 px-12 rounded-full mt-4 text-white text-xl mx-auto bg-primary-800"
      onClick={() => {
        window.open(appConstants.General.mainUrl, "_blank");
      }}
    >
      <i className="fa-solid fa-share mr-2"></i> Visit Website
    </button>
    <ConfettiComponent />
  </div>
);

const QuestionList = () => {
  const { currentQuestion, questions, title, organization_name } = useSelector(
    (state) => state.interview
  );

  return (
    <>
      {questions.length !== 0 ? (
        <div>
          {currentQuestion === -1 && <PreviewComponent />}
          {currentQuestion === -2 && (
            <OutroComponent title={title} org={organization_name} />
          )}
          {currentQuestion !== -1 && currentQuestion !== -2 && (
            <>
              <QuestionComponent />
              <div id="interview">
                <Pagination
                  pageSize={1}
                  onChange={() => {
                    return;
                  }}
                  current={currentQuestion + 1}
                  total={questions.length}
                  act
                />
              </div>
            </>
          )}
        </div>
      ) : (
        <div>
          <Typography.Title level={5} className="text-gray-500 text-center">
            No Questions Found
          </Typography.Title>
        </div>
      )}
    </>
  );
};

const PreviewComponent = () => {
  const [question, setQuestion] = useState(null);
  const { questions, id, candidate, interviewStatus } = useSelector(
    (state) => state.interview
  );
  const [currentQuestion, setCurrentQuestion] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    setQuestion(questions[currentQuestion]);
  }, [questions, currentQuestion]);

  const handleSubmitInterview = () => {
    const attachments = questions.map((question) => {
      return {
        question_id: question.id,
        answer:
          question.response_type === "video" ? question.answer : undefined,
        title: question.title,
        take: question.retakes_used,
        response_attachment_id: question.response_attachment_id,
        text_response:
          question.response_type === "text" ? question.answer : undefined,
      };
    });
    interviewActions.submitInterview(id, candidate.id, attachments)(dispatch);
  };

  const showRetakingConfirmation = (callback) => {
    Modal.confirm({
      title: "Are you sure you want to retake this question?",
      content: "Your Saved Recording wil be lost",
      okText: "Yes",

      cancelText: "No",
      onOk() {
        callback();
      },
    });
  };

  return (
    <div className="p-4 bg-white rounded-md -mt-10 -mb-12 md:-mb-16">
      <div className="text-center mb-4">
        <Typography.Title level={2} className="font-bold">
          Almost Done
        </Typography.Title>
        <Typography.Text className="text-gray-500 text-base">
          Please review your answers carefully and ensure you are happy with
          them.
        </Typography.Text>
      </div>
      <div className="flex flex-col md:flex-row " style={{ minHeight: "50vh" }}>
        <div className="w-full md:w-1/2 lg:w-7/12 text-base p-4 space-y-4 self-start mb-4">
          {question && (
            <>
              <div className="text-gray-500 font-semibold flex items-center">
                <i className="fa-solid fa-message mr-2" />
                <span> Question {currentQuestion + 1}</span>
              </div>
              <div
                className="custom-markdown text-black text-lg font-semibold"
                dangerouslySetInnerHTML={{ __html: question.title }}
              />
              {question.response_type === "text" ? (
                <div
                  className="aspect-video p-4 rounded-lg border-2 border-algaeGreen-500 overflow-y-auto"
                  id="preview"
                >
                  <div dangerouslySetInnerHTML={{ __html: question.answer }} />
                </div>
              ) : (
                <video
                  src={question.answer}
                  className="aspect-video w-full bg-black rounded-xl object-cover"
                  controls
                />
              )}
              <div className="text-center flex items-center justify-center">
                <div className="flex flex-col items-center relative w-max mb-6">
                  <button
                    className="bg-primary-800 py-2 px-12 rounded-full text-xl cursor-pointer disabled:cursor-not-allowed text-white w-max"
                    onClick={() => {
                      if (question.response_type === "video")
                        showRetakingConfirmation(() => {
                          interviewActions.setCurrentQuestion(currentQuestion)(
                            dispatch
                          );
                        });
                      else
                        interviewActions.setCurrentQuestion(currentQuestion)(
                          dispatch
                        );
                    }}
                    disabled={
                      question.retakes_used - question.retakes_allowed === 0
                    }
                  >
                    <i className="fa-solid fa-rotate-right mr-2"></i>
                    {question.retakes_used - question.retakes_allowed === 0
                      ? `${
                          question.response_type === "video"
                            ? "No Retakes"
                            : "No-Edits"
                        }`
                      : `${
                          question.response_type === "video" ? "Retake" : "Edit"
                        }`}
                  </button>
                  <small className="bottom-0 left-0  text-center w-full -mb-6 text-sm text-gray-500">
                    {question.response_type === "video" ? "Retakes" : "Edits"}{" "}
                    Pending :{" "}
                    {question.retakes_used
                      ? question.retakes_allowed - question.retakes_used
                      : question.retakes_allowed}
                  </small>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="w-full md:w-1/2 lg:w-5/12 overflow-y-auto self-stretch flex flex-col justify-between space-y-4 p-2 previewLayout">
          <div
            className="rounded-lg grow overflow-y-auto border-2 "
            style={{
              boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.5)",
            }}
          >
            <div className="text-gray-500 text-base font-semibold sticky pt-4 -mt-1 px-4 top-0 z-50 bg-white">
              Answer {currentQuestion + 1} of {questions.length}
            </div>
            <List
              className="px-2"
              itemLayout="horizontal"
              dataSource={questions}
              renderItem={(question, index) => (
                <List.Item>
                  <div
                    onClick={() => setCurrentQuestion(index)}
                    className={`h-full w-full flex flex-col rounded-lg p-2 ${
                      currentQuestion === index ? "bg-gray-200" : ""
                    }`}
                  >
                    <div className="flex flex-col self-stretch space-y-2 ">
                      <div className="flex items-center">
                        <i className="fa-solid fa-message mr-2" />
                        <span className="text-base">Question {index + 1} </span>
                      </div>
                      <div
                        className="custom-markdown text-black text-sm font-semibold flex-grow"
                        dangerouslySetInnerHTML={{
                          __html: handleConcat(question.title, 170),
                        }}
                      />
                    </div>
                    <div className="grow text-base flex space-x-2">
                      <div className="w-2/4 flex flex-col">
                        {question.response_type === "text" ? (
                          <></>
                        ) : (
                          <video
                            className="bg-black rounded-md aspect-video object-cover flex-grow"
                            src={question.answer}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </List.Item>
              )}
            />
          </div>
          <div className="text-center pb-4">
            <button
              disabled={interviewStatus === "submitting"}
              className="bg-primary-800 py-2 px-12 rounded-full text-xl cursor-pointer disabled:cursor-not-allowed text-white"
              onClick={(e) => {
                e.preventDefault();
                handleSubmitInterview();
              }}
            >
              {interviewStatus === "submitting" ? "Submitting" : "Submit"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const ConfettiComponent = () => {
  const [confetti, setConfetti] = useState(false);

  const { width, height } = useWindowSize();
  useEffect(() => {
    window.localStorage.removeItem("session");
    setConfetti(true);
    setTimeout(() => {
      setConfetti(false);
    }, 5000);
  }, []);

  return <>{confetti && <Confetti width={width} height={height} />}</>;
};
export default InterviewPage;
