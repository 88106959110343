import { message, Modal, Button, Typography } from "antd";
import { useEffect, useState } from "react";
import { authorize } from "../../api/authorization";
import { useNavigate } from "react-router-dom";

const AuthorizationPage = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [status, setStatus] = useState("pending");
  const urlParams = new URLSearchParams(window.location.search);
  const access_token = urlParams.get("access_token");
  const email = urlParams.get("email");

  let navigate = useNavigate();

  useEffect(() => {
    setIsModalVisible(true);
    if (access_token && email) {
      setStatus("verifying");
      authorize({
        email: email,
        api_key: access_token,
      })
        .then(({ data }) => {
          if (data.api_key) {
            localStorage.setItem("api_token", data.api_key);
            localStorage.setItem("api_key", access_token);
            localStorage.setItem("email", email);
            setStatus("verified");
            navigate("/interview");
          } else {
            setStatus("unauthorized");
            setTimeout(() => {
              window.location.href =
                process.env.REACT_APP_REDIRECT_URL +
                "/company/interviews/async-video";
            }, 2000);
          }
        })
        .catch(() => {
          setStatus("unauthorized");
          setTimeout(() => {
            window.location.href =
              process.env.REACT_APP_REDIRECT_URL +
              "/company/interviews/async-video";
          }, 2000);
        });
    } else {
      if (document.getElementsByClassName("ant-message").length > 0) {
        document.getElementsByClassName("ant-message")[0].style.visibility =
          "hidden";
        setTimeout(() => {
          document.getElementsByClassName("ant-message")[0].style.visibility =
            "visible";
        }, 5000);
      }
      if (localStorage.getItem("api_key")) {
        navigate("/interview");
      } else {
        setStatus("unauthorized");
        setTimeout(() => {
          window.location.href =
            process.env.REACT_APP_REDIRECT_URL +
            "/company/interviews/async-video";
        }, 2000);
      }
    }
  }, []);

  return (
    <>
      {/* <Modal
        visible={isModalVisible}
        wrapClassName="authorization_modal"
        closable={false}
        footer={false}
        title={
          <div className="text-xl text-center font-semibold">
            {status === "verified" && "Verified"}
            {status === "pending" || (status === "verifying" && "Verifying")}
            {status === "unauthorized" && "Unauthorized"}
          </div>
        }
        centered
        className="rounded-lg"
      >
        {status === "pending" ||
        status === "verified" ||
        status === "verifying" ? (
          <div className="flex flex-col items-center">
            <div className="flex flex-col items-center">
              <Typography.Text>
                {status === "pending" &&
                  "Please wait and experience secure access while we verify your credentials"}
                {status === "verified" &&
                  "Congratulations! You have been successfully verified and granted access"}
              </Typography.Text>
            </div>
          </div>
        ) : (
          <>
            <Typography.Text>
              Authenticate for exclusive access to our advanced platform's
              resources and actions.
            </Typography.Text>
            <br />
            <Typography.Text>
              Access the full capabilities of our platform by logging in now.
            </Typography.Text>
            <div className="mx-auto text-center mt-4">
              <a href= {`${process.env.REACT_APP_REDIRECT_URL}/login`}>
                <Button
                  size={"large"}
                  type="primary"
                  className="bg-primary-800 border-algaeGreen-500 rounded-full py-1 tex-white text-sm items-center justify-center px-4"
                >
                  Log in
                </Button>
              </a>
            </div>
          </>
        )}
      </Modal> */}
    </>
  );
};

export default AuthorizationPage;
