import API from "./axios";

export const createCandidate = (data, isDemo) => {
  return isDemo
    ? new Promise((resolve, reject) => {
      const dummyCandidate = {
        id: 1,
        uuid: "f5a509db-b21e-4629-95de-34a3832c645e",
        hiring_status: "applied",
        created: "2023-07-14T07:27:17.689950Z",
        modified: "2023-07-14T07:27:17.689980Z",
        first_name: "sampleFirst",
        last_name: "sampleLast",
        email: "sampleEmail",
        phone_number: "1234567890",
        isDemo: true,
        questions: [
          {
            id: 2,
            uuid: "44444-4444-4-4",
            title: "How are you today?",
            interview_id: 3,
            video_duration: "00:01:00",
            response_type: "video",
            retakes_allowed: 5,
            status: "pending",
            created: "2023-05-27T07:57:40.515039Z",
            modified: "2023-05-27T07:57:40.515068Z",
            is_deleted: false,
          },
        ],
      };
      resolve({ data: dummyCandidate });
    })
    : API.post(
      "/interviews/candidate/",
      {
        ...data,
        hiring_status: "applied",
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("api_key")}`,
        },
      }
    );
};

export const getCandidate = (id, interview_id) => {
  return API.get(
    `/interviews/candidate/${id}`,

    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("api_key")}`,
      },
    }
  );
};

export const updateCandidate = (id, interview_id, data) => {
  return API.patch(
    `/interviews/candidate/${id}`,
    data,

    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("api_key")}`,
      },
    }
  );
};

export const deleteCandidate = (id, interview_id) => {
  return API.delete(
    `/interviews/candidate/${id}`,

    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("api_key")}`,
      },
    }
  );
};
