const appConstants = {
  General: {
    BrandName: "Tacnique",
    LinkedInUrl: "https://www.linkedin.com/company/tacnique/",
    mainUrl: process.env.REACT_APP_REDIRECT_URL,
    FacebookUrl: "https://www.facebook.com/tacnique",
    InstagramUrl: "https://www.instagram.com/tacnique/",
    TwitterUrl: "https://twitter.com/tacnique",
    YoutubeUrl: "https://www.youtube.com/channel/UCI3x-yY0oNp8cawdJCjE2YQ",
  },
  Interview: {
    QuestionsDurations: [
      { label: "1 minutes", value: "00:01:00" },
      { label: "2 minutes", value: "00:02:00" },
      { label: "3 minutes", value: "00:03:00" },
      { label: "5 minutes", value: "00:05:00" },
    ],
    QuestionsType: [
      { label: "Video", value: "video" },
      { label: "Text", value: "text" },
    ],
    QuestionsRetakes: [
      { label: "1 Retake", value: 1 },
      { label: "2 Retakes", value: 2 },
      { label: "3 Retakes", value: 3 },
      { label: "4 Retakes", value: 4 },
      { label: "5 Retakes", value: 5 },
    ],
  },
  controls: {
    is_video_enabled: {
      key: "enabled camera",
      color: "#12727D",
      icon: <i className="fa-solid fa-camera"></i>,
    },
    is_focused: {
      key: "is focused",
      color: "#12727D",
      icon: <i className="fa-solid fa-record-vinyl"></i>,
    },
    is_hand_raised: {
      key: "raised their hand",
      color: "#f7ee88",
      icon: <i className="fa fa-light fa-hand"></i>,
    },
    is_sharing_screen: {
      key: "started screen sharing",
      color: "#12727D",
      icon: <i className="fa-solid fa-display"></i>,
    },
    audio_recording_enabled: {
      key: "enabled audio recording",
      color: "#12727D",
      icon: <i className="fa-solid fa-microphone"></i>,
    },
    video_recording_enabled: {
      key: "enabled video recording",
      color: "#12727D",
      icon: <i className="fa-solid fa-video"></i>,
    },
    disabled_audio: {
      key: "disabled microphone",
      color: "#FF4D4D",
      icon: <i className="fa-solid fa-microphone-slash"></i>,
    },
    disabled_video: {
      key: "disabled camera",
      color: "#FF4D4D",
      icon: (
        <>
          <i className="fa-solid fa-camera"></i>
          <i className="icon-stack fa-solid fa-slash"></i>
        </>
      ),
    },
    stopped_is_focused: {
      key: "is no longer focused",
      color: "#FF4D4D",
      icon: <i className="fa-solid fa-record-vinyl"></i>,
    },
    stopped_sharing_screen: {
      key: "stopped screen sharing",
      color: "#FF4D4D",
      icon: (
        <>
          <i className="fa-solid fa-display"></i>
          <i className="icon-stack fa-solid fa-slash"></i>
        </>
      ),
    },
    stopped_hand_raise: {
      key: "put their hand down",
      color: "#b3b300",
      icon: <i className="fa-rotate-180 fa fa-light fa-hand"></i>,
    },
    is_audio_enabled: {
      key: "enabled microphone",
      color: "#12727D",
      icon: <i className="fa-solid fa-microphone"></i>,
    },
  },
};
export default appConstants;
