import { useParams, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Col, Form, Input, message, Row, Select, Tabs, Tooltip } from "antd";
import { getInterview, updateInterview } from "../../api/interviews";
import {
  createQuestion,
  deleteQuestion,
  updateQuestion,
} from "../../api/questions";
import appConstants from "../../documents/constants";
import {
  CloseOutlined,
  MinusCircleOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import InterviewResponsesPage from "./InterviewResponsesPage";
import { CustomInput, errorValidator } from "../../utilities";

const InterviewManagerPage = () => {
  let navigate = useNavigate();
  return (
    <div className="interview_wrappper">
      <div className="max-w-3xl mx-auto py-4 lg:max-w-7xl inline-flex justify-between w-full">
        <button
          className="inline-flex items-center px-4 py-2 rounded-md border border-gray-400 bg-white text-sm font-medium text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:border-gray-400 shadow-sm"
          onClick={() => {
            navigate("/interview");
          }}
        >
          Back
        </button>
      </div>
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="View Responses" key="1" className="text-sm">
          <InterviewResponsesPage />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Update Interview" key="2" className="text-sm">
          <InterviewUpdatePage />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export default InterviewManagerPage;

const InterviewUpdatePage = () => {
  let { uuid } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [interviewInfo, setInterviewInfo] = useState({
    title: "",
    id: 0,
    questions: [],
  });
  const [form] = Form.useForm();
  useEffect(() => {
    setIsLoading(true);
    // message.loading({ content: "Loading Async Interview", key: "loading" });
    getInterview(uuid)
      .then(({ data }) => {
        delete data["organization_id"];
        setInterviewInfo(data);
        form.setFieldsValue(data);
        message.destroy({ key: "loading" });
        setIsLoading(false);
      })
      .catch(() => {
        message.error({
          content: "Error Loading Async Interview",
          key: "loading",
        });
        setIsLoading(false);
      });
  }, []);

  const handleUpdateTitle = (values) => {
    setIsLoading(true);
    message.loading({ content: "Updating Title", key: "title" });
    updateInterview(uuid, { ...interviewInfo, ...values })
      .then(({ data }) => {
        delete data["organization_id"];
        setInterviewInfo(data);
        message.success({ content: "Title Updated", key: "title" });
      })
      .catch(() => {
        message.error({ content: "Error Updating Title", key: "title" });
      });
  };

  const updateQuestionList = (action, id, data) => {
    let questions = interviewInfo.questions || [];
    switch (action) {
      case "create":
        questions = [...questions, data];
        break;
      case "delete":
        questions = questions.filter((que) => que.id !== id);
        break;
      case "update":
        questions = questions.map((que) => {
          if (que.id === id) {
            return data;
          }
          return que;
        });
    }
    setInterviewInfo({ ...interviewInfo, questions });
  };

  const handleUpdateQuestion = (values, id) => {
    setIsLoading(true);
    message.loading({ content: "Updating Question", key: "question" });
    updateQuestion(values, id, interviewInfo.id)
      .then(({ data }) => {
        updateQuestionList("update", id, { ...data, id: id });
        message.success({ content: "Question Updated", key: "question" });
      })
      .catch(() => {
        message.error({ content: "Error Updating Question", key: "question" });
      });
  };

  const handleDeleteQuestion = (id) => {
    setIsLoading(true);
    message.loading({ content: "Deleting Question", key: "question" });
    deleteQuestion(id, interviewInfo.id)
      .then(() => {
        updateQuestionList("delete", id);
        message.success({ content: "Question Deleted", key: "question" });
      })
      .catch(() => {
        message.error({ content: "Error Deleting Question", key: "question" });
      });
  };

  const [isAddVsiible, setIsAddVisible] = useState(false);

  return (
    <div className=" mx-auto">
      <div className="my-2 p-4 bg-white rounded">
        <Form
          onFinish={handleUpdateTitle}
          layout="vertical"
          onValuesChange={(changedValues) => {
            setInterviewInfo({ ...interviewInfo, ...changedValues });
          }}
          form={form}
          initialValues={{ title: interviewInfo.title }}
        >
          <Form.Item
            label={
              <div className="mb-7 text-2xl ml-2">Async Interview Title</div>
            }
            name="title"
            validateTrigger={["onChange", "onBlur"]}
            rules={[
              {
                max: 255,
                min: 1,
                message: "Max 255 & Min 1 characters is allowed",
              },
            ]}
          >
            <Input.TextArea
              className="c_input"
              style={{
                borderRadius: "10px",
              }}
              placeholder="Enter the interview title"
            />
          </Form.Item>
          <Form.Item>
            <button
              type="submit"
              className="mt-4 py-2 px-3 bg-primary-800 text-xs text-white rounded-md"
            >
              Update Title
            </button>
          </Form.Item>
        </Form>
      </div>
      <hr className="mt-7" />

      <ul>
        {interviewInfo.questions &&
          interviewInfo.questions.map((que, i) => {
            return (
              <li key={que.id}>
                {" "}
                <QuestionTile
                  que={que}
                  i={i}
                  handleUpdate={handleUpdateQuestion}
                  handleDelete={handleDeleteQuestion}
                />
              </li>
            );
          })}
      </ul>
      {isAddVsiible ? (
        <QuestionCreateTile
          i={(interviewInfo.questions && interviewInfo.questions.length) || 0}
          handleListUpdate={updateQuestionList}
          onSave={() => {
            setIsAddVisible(false);
          }}
        />
      ) : (
        <div className=" p-4 md:p-3 text-end">
          <button
            type="submit"
            className=" py-2 px-4 bg-primary-800 text-white rounded-md mr-2"
            onClick={() => {
              setIsAddVisible(true);
            }}
          >
            Add Question
          </button>
        </div>
      )}
    </div>
  );
};

const QuestionTile = ({ que, i, handleDelete, handleUpdate }) => {
  const [form] = Form.useForm();
  const [type, setType] = useState(que.response_type);
  return (
    <Form
      onFinish={(values) => {
        handleUpdate(values, que.id);
      }}
      initialValues={que}
    >
      <div className="flex w-100 bg-white p-4 pt-6  rounded-md my-4 relative">
        <div className="grow">
          <div className="py-2 text-xl mb-3">Question {i + 1}</div>
          <Form.Item name="title" rules={[{ validator: errorValidator }]}>
            <CustomInput />
          </Form.Item>
          <Row className="mt-10" gutter={10}>
            <Col span={8}>
              <div className="flex flex-col">
                <div className="c_label mb-4">Retakes</div>
                <Row className="grow">
                  <div className="grow">
                    <Form.Item
                      name="retakes_allowed"
                      rules={[
                        {
                          required: true,
                          message: "Please Select No. of Retakes Allowed",
                        },
                      ]}
                    >
                      <Select className=" w-full rounded ">
                        {appConstants.Interview.QuestionsRetakes.map((item) => (
                          <Select.Option key={item.label} value={item.value}>
                            {item.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </Row>
              </div>
            </Col>
            <Col span={8}>
              <div className="flex flex-col">
                <div className="c_label mb-4 ">Type</div>
                <Row className="grow">
                  <div className="grow ">
                    <Form.Item
                      name="response_type"
                      rules={[
                        {
                          required: true,
                          message: "Please Select The Response Type",
                        },
                      ]}
                    >
                      <Select
                        className=" w-full rounded"
                        onChange={(value) => {
                          setType(value);
                        }}
                      >
                        {appConstants.Interview.QuestionsType.map((item) => (
                          <Select.Option key={item.label} value={item.value}>
                            {item.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </Row>
              </div>
            </Col>
            <Col span={8}>
              {type === "video" && (
                <div className="flex flex-col">
                  <div className="c_label mb-4 ">Duration</div>
                  <Row className="grow">
                    <div className="grow ">
                      <Form.Item
                        name="video_duration"
                        rules={[
                          {
                            required: true,
                            message: "Please Select The Response Duration",
                          },
                        ]}
                      >
                        <Select className=" w-full rounded">
                          {appConstants.Interview.QuestionsDurations.map(
                            (item) => (
                              <Select.Option
                                key={item.label}
                                value={item.value}
                              >
                                {item.label}
                              </Select.Option>
                            )
                          )}
                        </Select>
                      </Form.Item>
                    </div>
                  </Row>
                </div>
              )}
            </Col>
            <Col span={24} className="flex justify-between">
              <button
                type="submit"
                className="mt-4 py-2 px-3 bg-primary-800 text-sm text-white rounded-md"
              >
                Update Question
              </button>
              <button
                onClick={() => handleDelete(que.id)}
                type="button"
                className="mt-4 py-2 px-3 bg-red-500 text-sm text-white rounded-md"
              >
                Delete Question
              </button>
            </Col>
          </Row>
        </div>
      </div>
    </Form>
  );
};

const QuestionCreateTile = ({ i, handleListUpdate, onSave }) => {
  let { uuid } = useParams();
  const newQuestion = {
    title: "",
    response_type: "video",
    retakes_allowed: 1,
  };

  const [isLoading, setIsLoading] = useState(false);

  const addQuestion = (values) => {
    setIsLoading(true);
    message.loading({ content: "Adding Question", key: "question" });
    createQuestion(values, uuid)
      .then(({ data }) => {
        handleListUpdate("create", undefined, data);
        resetForm();
        message.success({ content: "Question Added", key: "question" });
        onSave();
      })
      .catch(() => {
        message.error({ content: "Error Adding Question", key: "question" });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const resetForm = () => {
    formInstance.resetFields();
  };
  const [type, setType] = useState("video");
  const [formInstance] = Form.useForm();
  return (
    <Form
      onFinish={addQuestion}
      form={formInstance}
      initialValues={newQuestion}
    >
      <div className="flex w-100 bg-white p-4 pt-6  rounded-md my-4 relative">
        <div className="grow">
          <div className="py-2 text-xl mb-3">Question {i + 1}</div>
          <Form.Item name="title" rules={[{ validator: errorValidator }]}>
            <CustomInput />
          </Form.Item>
          <Row>
            <Col span={8} md={8}>
              <div className="flex flex-col">
                <div className="c_label mb-4">Retakes</div>
                <Row className="grow">
                  <div className="grow ">
                    <Form.Item
                      name="retakes_allowed"
                      rules={[
                        {
                          required: true,
                          message: "Please Select No. of Retakes Allowed",
                        },
                      ]}
                    >
                      <Select className=" w-full rounded ">
                        {appConstants.Interview.QuestionsRetakes.map((item) => (
                          <Select.Option key={item.label} value={item.value}>
                            {item.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </Row>
              </div>
            </Col>
            <Col span={8}>
              <div className="flex flex-col">
                <div className="c_label mb-4 ml-2">Type</div>
                <Row className="grow">
                  <div className="grow ml-2">
                    <Form.Item
                      name="response_type"
                      rules={[
                        {
                          required: true,
                          message: "Please Select The Response Type",
                        },
                      ]}
                    >
                      <Select
                        className=" w-full rounded"
                        onChange={(value) => setType(value)}
                      >
                        {appConstants.Interview.QuestionsType.map((item) => (
                          <Select.Option key={item.label} value={item.value}>
                            {item.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </Row>
              </div>
            </Col>
            <Col span={8} className="pl-2">
              {type === "video" && (
                <div className="flex flex-col ">
                  <div className="c_label mb-4 ml-2">Duration</div>
                  <Row className="grow">
                    <div className="grow mr-4">
                      <Form.Item
                        initialValue={"00:01:00"}
                        name="video_duration"
                        className="ml-2"
                        rules={[
                          {
                            required: true,
                            message: "Please Select The Response Duration",
                          },
                        ]}
                      >
                        <Select className=" w-full rounded">
                          {appConstants.Interview.QuestionsDurations.map(
                            (item) => (
                              <Select.Option
                                key={item.label}
                                value={item.value}
                              >
                                {item.label}
                              </Select.Option>
                            )
                          )}
                        </Select>
                      </Form.Item>
                    </div>
                  </Row>
                </div>
              )}
            </Col>
            <Col span={24} lg={24}>
              <div className="flex">
                <Row className="grow">
                  <div className="grow mt-10 text-end">
                    <Form.Item>
                      <button
                        type="submit"
                        className=" py-2 px-4 bg-primary-800 text-sm text-white rounded-md mr-2"
                      >
                        Save Question
                      </button>
                    </Form.Item>
                  </div>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Form>
  );
};