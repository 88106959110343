import API from "./axios";

export const getMessages = (event_id) => {
  return API.get(`/rooms/events/messages/`, {
    params: {
      event_id,
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem("api_key")}`,
    },
  });
};
