import API from "./axios";

export const getQuestions = (interview_id) => {
  return API.get(`/interviews/questions/`, {
    params: {
      interview_id: interview_id,
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem("api_key")}`,
    },
  });
};
export const createQuestion = (data, interview_id) => {
  return API.post(
    "/interviews/questions/",
    { ...data, interview_id: interview_id },
    {
      params: {
        interview_id: interview_id,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("api_key")}`,
      },
    }
  );
};
export const getQuestion = (id, interview_id) => {
  return API.get(`/interviews/questions/${id}`, {
    params: {
      interview_id: interview_id,
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem("api_key")}`,
    },
  });
};

export const uploadAttachments = (data, interview_id) => {
  return API.post("/interviews/bulk-attachment/", data, {
    params: {
      interview_id: interview_id,
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem("api_key")}`,
    },
  });
};

export const getAttachments = (interviewId, candidateId) => {
  return API.get("/interviews/attachment/", {
    params: {
      interview_id: interviewId,
      candidate_id: candidateId,
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem("api_key")}`,
    },
  });
};

export const deleteQuestion = (id, interview_id) => {
  return API.delete(`/interviews/questions/${id}/`, {
    params: {
      interview_id: interview_id,
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem("api_key")}`,
    },
  });
};

export const updateQuestion = (data, id, interview_id) => {
  return API.patch(
    `/interviews/questions/${id}/`,
    { ...data, interview_id: interview_id },
    {
      params: {
        interview_id: interview_id,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("api_key")}`,
      },
    }
  );
};
