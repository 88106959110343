import "./documents/App.scss";
import "./documents/antd.compact.scss";
import "./documents/custom.scss";
import "video.js/dist/video-js.css";
import InterviewCreatePage from "./pages/interview/InterviewCreatePage";
import FooterComponent from "./components/Layout/FooterComponent";
import HeaderComponent from "./components/Layout/HeaderComponent";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import InterViewPreviewPage from "./pages/interview/InterViewPreviewPage";
import InterviewResponsesPage from "./pages/interview/InterviewResponsesPage";
import { InterviewListPage } from "./pages/interview/InterviewListPage";
import CandidateResponse from "./pages/interview/CandidateResponse";
import CandidateInfoPage from "./pages/Candidate/CandidateInfoPage";
import AuthorizationPage from "./pages/Authorization/AuthorizationPage";
import AttachmentsPage from "./pages/Attachments/AttachmentsPage";
import MessagesPage from "./pages/Messages/MessagesPage";
import ParticipantsPage from "./pages/Participants/ParticipantsPage";
import ControlsPage from "./pages/Controls/ControlsPage";
import InterViewInvitePage from "./pages/interview/InterviewInvitePage";
import { Provider } from "react-redux";
import store from "./redux/store";
import InterviewPage from "./pages/interview/InterviewPage";
import AuthManager from "./components/AuthManager";
import InterviewManagerPage from "./pages/interview/InterviewUpdatePage";
import InterViewSharePage from "./pages/interview/InterviewSharePage";

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <div className="flex flex-col min-h-screen w-full bg-white">
          <HeaderComponent />
          <div className="container px-1 md:px-8 mx-auto my-5 grow max-w-7xl">
            <Routes>
              <Route path="/authorization" element={<AuthorizationPage />} />
              <Route
                path="async-interview/:uuid/responses/:id/candidate"
                element={
                  <AuthManager>
                    <CandidateInfoPage />
                  </AuthManager>
                }
              />
              <Route
                path="async-interview/:uuid/responses/:responseUuid"
                element={
                  <AuthManager>
                    <InterViewPreviewPage />
                  </AuthManager>
                }
              />
              <Route
                path="async-interview/:uuid/share"
                element={<InterViewSharePage />}
              />
              <Route
                path="async-interview/:uuid/responses/"
                element={
                  <AuthManager>
                    <InterviewResponsesPage />
                  </AuthManager>
                }
              />
              <Route
                path="sync-interview/:uuid/room/:roomId/attachments/"
                element={
                  <AuthManager>
                    <AttachmentsPage />
                  </AuthManager>
                }
              />
              <Route
                path="sync-interview/:uuid/room/:roomId/messages/"
                element={
                  <AuthManager>
                    <MessagesPage />
                  </AuthManager>
                }
              />
              <Route
                path="sync-interview/:uuid/room/:roomId/participants"
                element={
                  <AuthManager>
                    <ParticipantsPage />
                  </AuthManager>
                }
              />
              <Route
                path="sync-interview/:uuid/room/:roomId/controls"
                element={
                  <AuthManager>
                    <ControlsPage />
                  </AuthManager>
                }
              />
              <Route
                path={"async-interview/:uuid/test"}
                element={<InterviewPage />}
              />
              <Route
                path="async-interview/submit/:uuid"
                element={<CandidateResponse />}
              />
              <Route
                path="async-interview/:uuid"
                element={
                  <AuthManager>
                    <InterviewManagerPage />
                  </AuthManager>
                }
              />
              <Route
                path="async-interview/:uuid/invite"
                element={<InterViewInvitePage />}
              />

              <Route
                path="async-interview/create"
                element={
                  <AuthManager>
                    <InterviewCreatePage />
                  </AuthManager>
                }
              />
              <Route
                path="interview"
                element={
                  <AuthManager>
                    <InterviewListPage />
                  </AuthManager>
                }
              />
              <Route
                path="/"
                element={
                  <AuthManager>
                    <Navigate to="/interview" />
                  </AuthManager>
                }
              />
            </Routes>
          </div>
          <FooterComponent />
        </div>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
