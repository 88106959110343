// noinspection ES6UnusedImports

import React, { Component } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "webrtc-adapter";
import RecordRTC from "recordrtc";
import "videojs-record/dist/css/videojs.record.css";
import { Col, Row } from "antd";
import { VideoCameraOutlined } from "@ant-design/icons";

class VideoRecorderComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRecording: false,
      isReady: false,
      isMounted: false,
    };
  }

  componentDidMount() {
    if (this.state.isMounted) {
    } else {
      this.setState({ isMounted: true }, () => {
        this.player = videojs(this.videoNode, this.props, () => {
          // print version information at startup
          const version_info =
            "Using video.js " +
            videojs.VERSION +
            " with videojs-record " +
            videojs.getPluginVersion("record") +
            " and recordrtc " +
            RecordRTC.version;
          videojs.log(version_info);
        });

        // device is ready
        this.player.on("deviceReady", () => {
          this.setState({ isReady: true });
        });

        // user clicked the record button and started recording
        this.player.on("startRecord", () => {
          // return false;
          this.props.onRecordingStart();
          this.setState({ isRecording: true });
        });

        // user completed recording and stream is available
        this.player.on("finishRecord", () => {
          // recordedData is a blob object containing the recorded data that
          // can be downloaded by the user, stored on server etc.
          this.props.onFinish(this.player.recordedData);
          this.setState({ isRecording: false });
          this.player.record().stopStream();
          this.setState({ isReady: false });
        });

        // error handling
        this.player.on("error", (element, error) => {
          // console.warn(error);
        });

        this.player.on("deviceError", () => {
          // console.error("device error:", this.player.deviceErrorCode);
          this.setState({ isReady: false });
        });
      });
    }
  }

  // destroy player on unmount
  //   componentWillUnmount() {
  //     if (this.player) {
  //       this.player.dispose();
  //     }
  //   }
  render() {
    return (
      <Row>
        <Col span={6}>
          <div className="mt-14 ">
            <button
              className={
                this.props.attempts === this.props.que.retakes_allowed + 1
                  ? "opacity-70 cursor-not-allowed"
                  : ""
              }
              type="button"
              disabled={
                this.props.attempts === this.props.que.retakes_allowed + 1
              }
            >
              {this.state.isReady ? (
                <>
                  {this.state.isRecording ? (
                    <div
                      className="bg-primary-800 px-4 py-2 rounded-md text-white"
                      type="button"
                      onClick={() =>
                        this.props.attempts !==
                          this.props.que.retakes_allowed + 1 &&
                        this.player &&
                        this.player["record"] &&
                        this.player.record().stop()
                      }
                      style={{
                        backgroundColor: "#246a7b",
                        borderColor: "#246a7b",
                      }}
                    >
                      Stop Recording
                    </div>
                  ) : (
                    <button
                      className="bg-primary-800 flex px-4 py-2 rounded-md text-white"
                      type="button"
                      onClick={() =>
                        this.props.attempts !==
                          this.props.que.retakes_allowed + 1 &&
                        this.state.isReady &&
                        this.player.record().start()
                      }
                    >
                      <div> Record</div>{" "}
                      <VideoCameraOutlined className="text-xl ml-2 -mb-1 " />
                    </button>
                  )}{" "}
                </>
              ) : (
                <div
                  className="px-4 py-2 rounded-md text-white"
                  type="button"
                  onClick={() => {
                    this.props.attempts !==
                      this.props.que.retakes_allowed + 1 &&
                      this.player.record().getDevice();
                  }}
                  style={{
                    backgroundColor: "#246a7b",
                    borderColor: "#246a7b",
                  }}
                >
                  Turn On Camera
                </div>
              )}
            </button>
          </div>
        </Col>
        <Col span={18}>
          <div>
            <div data-vjs-player className="w-full z-10">
              <video
                id="myVideo"
                ref={(node) => (this.videoNode = node)}
                className={`video-js vjs-default-skin mx-auto ${
                  this.props.attempts === this.props.que.retakes + 1
                    ? "record-disabled"
                    : ""
                }`}
                playsInline
              ></video>
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}

export default VideoRecorderComponent;
